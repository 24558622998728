import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCursosTrainers = () => {
    const context = useContext(LayautContext);

    return {
        // Informacion principal
        cursosInt: context.llamarServidor('Curso/getCursoTrainer'),
        getInfoInt: context.llamarServidor('IntInicial/getInfoInt/'),
        insertCursoTrainer: context.llamarServidorRespuesta('Curso/insertCursoTrainer'),
        updateCursoTrainer: context.llamarServidorRespuesta('Curso/updateCursoTrainer'),
        buscaCursosTrainer: context.llamarServidor('Curso/buscaCursosTrainer'),
        borrarCurso: context.llamarServidorRespuesta('Curso/borrarCurso')
    };
};
export default ServiciosCursosTrainers;
