import React, { useState, useEffect } from 'react';
import { School, Group } from '@mui/icons-material';
import { Card, CardContent, Grid, Typography, Tooltip, IconButton, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SubCardCollapase from 'ui-component/cards/SubCardCollapase';
import { BigPlayButton, Player } from 'video-react';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PersonIcon from '@mui/icons-material/Person';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

import ServiciosCatalogo from '../Components/services';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const useStyles = makeStyles((theme) => ({
    curso: {
        backgroundColor: theme.palette.secondary[800]
    },
    grupo: {
        borderRadius: '8px',
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary[800]
    }
}));

const ItemCorreo = ({ email }) => {
    return (
        <span>
            <h4>
                <ForwardToInboxIcon fontSize="small" /> Email: &nbsp;
                <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`} target="_blank" rel="noreferrer">
                    {email}
                </a>
            </h4>
        </span>
    );
};

const Contacto = ({ infoCotac, tipoCurso }) => {
    const classes = useStyles();
    const services = ServiciosCatalogo();
    const [correos, setCorreos] = useState([]);
    useEffect(() => {
        if (tipoCurso && Number(tipoCurso) == 2) {
            services.buscaCorreosDepto(
                (res) => {
                    setCorreos(res.data);
                },
                { idDepto: infoCotac?.depto }
            );
        } else {
            services.buscaCorreosDepto(
                (res) => {
                    setCorreos(res.data);
                },
                { idDepto: 0 }
            );
        }
    }, [infoCotac, tipoCurso]);

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <ul>
                    <span>
                        <h4>
                            <PersonIcon fontSize="small" /> Especialista de capacitación
                        </h4>
                    </span>
                    {correos?.length > 0 && correos?.map((item) => <ItemCorreo key={item.idCorreo} email={item.correo} />)}
                </ul>
            </Grid>
        </Grid>
    );
};

export default Contacto;
