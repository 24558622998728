import React, { useState, useEffect, useContext } from 'react';
import { AddBox } from '@mui/icons-material';
import { ModalGeneral, Tabla, AutocompleteForm } from 'ui-component';
import MainCard from 'ui-component/cards/MainCard';
import { useForm } from 'react-hook-form';
import { Button, Grid, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Services from 'views/Incognito/components/services';
import HomeInicio from 'views/HomeParticipantes';
import LayautContext from 'Context/ContextLayaut';
import { useLogin } from 'Context/ContextLogin';
import DialogoCursoIncognito from './components/DialogoCursoIncognito';

const useStyles = makeStyles((theme) => ({
    btnAceptar: {
        color: theme.palette.btn.green,
        '&:hover': {
            backgroundColor: theme.palette.btn.green,
            color: theme.palette.btn.white
        }
    },
    btnCancelar: {
        color: theme.palette.btn.red,
        '&:hover': {
            backgroundColor: theme.palette.btn.red,
            color: theme.palette.btn.white
        }
    },
    btnIngresar: {
        color: theme.palette.btn.blue,
        '&:hover': {
            backgroundColor: theme.palette.btn.blue,
            color: theme.palette.btn.white
        }
    }
}));

const Incognito = ({ permisos }) => {
    const classes = useStyles();
    const services = Services();
    const {
        usuario: { areas }
    } = useLogin();
    const { control, setValue, handleSubmit } = useForm();
    // States
    const [asesores, setAsesores] = useState([]);
    const [modal, setModal] = useState({});
    const [ctl, setCtl] = useState({ estatusGrupo: [] });
    const [option, setOption] = useState(areas[0]?.idarea || '');

    const context = useContext(LayautContext);

    const getAsesores = (option) => {
        services.getAsesores(
            (res) => {
                setAsesores(res);
            },
            { option }
        );
    };

    const ingresarSesion = (data) => {
        const asesor = data;
        const idCRM = ['1', '2'].includes(option) ? asesor.idcontrato : asesor.num_empleado;
        services.ingresarPerfil(
            (res) => {
                const usuario = res.usuario;
                if (!usuario || usuario == null) {
                    return context.mensaje({ open: true, status: -1, value: 'No se encontró el usuario.' });
                }
                setModal({
                    open: true,
                    titulo: asesor.nombre_persona,
                    props: { usuario: usuario, permisos, option, esIncognito: true },
                    componente: DialogoCursoIncognito,
                    cerrar: () => setModal({})
                });
                return 1;
            },
            { idCRM, option }
        );
    };

    const clickCambioTipo = (event, value) => {
        setOption(value);
        getAsesores(value);
        setValue('asesor', null);
    };

    const columnas = [
        { label: '# empleado', name: 'num_empleado', filtrar: true },
        { label: '# contrato', name: 'idcontrato', filtrar: true },
        { label: 'Nombre', name: 'nombre_persona', filtrar: true },
        { label: 'Puesto', name: 'nom_puesto', filtrar: true },
        { label: 'Sede', name: 'nom_sede', filtrar: true },
        { label: 'Departamento', name: 'nom_area', filtrar: true },
        { label: 'Área', name: 'nom_subarea', filtrar: true },
        { label: 'Estatus', name: 'estatus_vacante', filtrar: true }
    ];

    const accionesFila = [{ label: 'Ingresar', onClick: ingresarSesion, enabled: (row) => row.estatus_vacante == 'ACTIVO' }];

    useEffect(() => {
        if (areas?.length) {
            setOption(areas[0].idarea);
            clickCambioTipo({}, areas[0]?.idarea);
        }
    }, [areas]);

    return (
        <MainCard title="Incógnito">
            <form onSubmit={handleSubmit(ingresarSesion)}>
                <Grid container spacing={2}>
                    <Grid item>
                        {areas.length > 1 && (
                            <Grid item>
                                <Tabs value={option ? option : areas[0].idarea} onChange={clickCambioTipo} aria-label="basic tabs example">
                                    {areas.map((area) => (
                                        <Tab label={area.narea} value={area.idarea} />
                                    ))}
                                </Tabs>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Tabla rows={option == 0 ? [] : asesores} columns={columnas} accionesFila={accionesFila} columnKey="idcontrato" />
                    </Grid>
                </Grid>
            </form>
            <ModalGeneral
                open={modal?.open}
                titulo={modal?.titulo}
                width="lg"
                acciones={
                    <Button onClick={modal?.cerrar} className={classes.btnCancelar}>
                        Cerrar
                    </Button>
                }
            >
                {modal?.componente && <modal.componente {...modal?.props} />}
            </ModalGeneral>
        </MainCard>
    );
};

export default Incognito;
