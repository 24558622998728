import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCatalogo = () => {
    const context = useContext(LayautContext);

    return {
        getInfoInicial: context.llamarServidor('Trainer/getInfoInicial/'),
        getInfoNuevo: context.llamarServidor('Trainer/getInfoNuevo/'),
        getSinAsignar: context.llamarServidor('Trainer/getSinAsignar/'),

        ascenderATrainer: context.llamarServidorRespuesta('Trainer/ascenderATrainer/'),
        degradarTrainer: context.llamarServidorRespuesta('Trainer/degradarTrainer/'),

        agregarColaborador: context.llamarServidorRespuesta('Trainer/agregarColaborador'),
        getGrupos: context.llamarServidor('Trainer/getGrupos'),
        getGruposDefault: context.llamarServidor('Trainer/getGruposDefault'),
        getGruposTrainer: context.llamarServidor('Trainer/getGruposTrainer'),
        getGruposColab: context.llamarServidor('Trainer/getGruposColab'),

        agregarGrupoTrainer: context.llamarServidorRespuesta('Trainer/agregarGrupoTrainer'),
        getAlumnosGrupo: context.llamarServidor('Trainer/getAlumnosGrupo'),

        bloquearColaborador: context.llamarServidorRespuesta('Trainer/bloquearColaborador'),
        bloquearColaboradorMasivo: context.llamarServidorRespuesta('Trainer/bloquearColaboradorMasivo'),

        getCursos: context.llamarServidor('Trainer/getCursos'),
        getContenido: context.llamarServidor('Trainer/getsesionCurso'),
        cambiarEstatusSesionCurso: context.llamarServidorRespuesta('Trainer/cambiarEstatusSesionCurso'),

        getActividades: context.llamarServidor('Trainer/getActividades'),
        cambiarEstatusActividad: context.llamarServidorRespuesta('Trainer/cambiarEstatusActividad')
    };
};
export default ServiciosCatalogo;
