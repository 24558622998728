import { AddBox } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { Tabla, ModalFormularioV2, AvisoConfirmacion } from 'ui-component';
import MainCard from 'ui-component/cards/MainCard';
import Services from 'views/Configuracion/Components/Services';
import LayautContext from 'Context/ContextLayaut';
import FormCorreoContacto from '../Components/FormCorreoContacto';
import { width } from '@mui/system';

const CorreosContacto = (props) => {
    const context = useContext(LayautContext);
    const [confirm, setConfirm] = useState({ open: false });
    const services = Services();
    const [data, setData] = useState([]);
    const [deptos, setDeptos] = useState([]);
    const abrirNuevoCorreo = () => () => {
        context.formulario.abrir({
            open: true,
            titulo: 'Agregar correo de contacto',
            propsForm: { deptos: deptos },
            width: 'md',
            formulario: FormCorreoContacto,
            aceptar: (formData) => {
                services.guardaCorreo(
                    (res) => {
                        let arrActual = data;
                        arrActual.push(res);
                        setData([...arrActual]);
                        context.formulario.cerrar();
                    },
                    { ...formData }
                );
            }
        });
    };

    const acciones = [{ label: 'Nuevo correo', icon: <AddBox />, click: abrirNuevoCorreo, disabled: false }];

    const columns = [
        { label: 'dirección de correo', name: 'correo', filtrar: true },
        { label: 'departamentos', name: 'nombres', filtrar: true }
    ];

    const editarCorreo = (item) => {
        context.formulario.abrir({
            open: true,
            titulo: 'Editar correo de contacto',
            propsForm: { deptos: deptos, defaultItem: item },
            width: 'md',
            formulario: FormCorreoContacto,
            aceptar: (formData) => {
                formData.idCorreo = item.idCorreo;
                services.editaCorreo(
                    (res) => {
                        let arrActual = data;

                        let index = arrActual.findIndex((x) => x.idCorreo == res.idCorreo);
                        if (index != -1) {
                            arrActual[index] = res;
                            setData([...arrActual]);
                            context.formulario.cerrar();
                        }
                    },
                    { ...formData }
                );
            }
        });
    };

    const eliminaCorreo = (item) => {
        setConfirm({
            open: true,
            text: `El correo electronico "${item.correo}" ya no sera visualizado en el panel de alumno. ¿Desa continuar?`,
            aceptar: () =>
                services.eliminaCorreo(
                    (nuevaData) => {
                        let arrActual = data;

                        if (nuevaData == true) {
                            let index = arrActual.findIndex((x) => x.idCorreo == item.idCorreo);
                            if (index != -1) {
                                arrActual.splice(index, 1);
                                setData([...arrActual]);
                                setConfirm({ open: false });
                            }
                        }
                    },
                    { idCorreo: item.idCorreo }
                )
        });
    };

    const accionesFila = [
        { label: 'Editar', onClick: editarCorreo, enabled: (row) => true },
        { label: 'Eliminar', onClick: eliminaCorreo, enabled: (row) => true }
    ];

    useEffect(() => {
        services.getCorreos((res) => {
            setData(res.data);
            setDeptos(res.deptos);
        });
    }, []);

    return (
        <MainCard title="Correos de contacto">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Tabla rows={data} columns={columns} accionesFila={accionesFila} accionesTabla={acciones} columnKey="idCorreo" />
                </Grid>
            </Grid>

            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
        </MainCard>
    );
};

export default CorreosContacto;
