import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCatalogo = () => {
    const context = useContext(LayautContext);

    return {
        getReporteModulo: context.llamarServidor('Reportes/getReporteModulo/'),
        grupoInt: context.llamarServidor('Grupo/getGrupoIntTodos'),
        buscaCursoByTipo: context.llamarServidor('Curso/buscaCursoByTipo'),
        getReporteCurso: context.llamarServidor('Reportes/getReporteCurso'),
        getReporteCursoAct: context.llamarServidor('Reportes/getReporteCursoAct'),

        buscaActividades: context.llamarServidor('Curso/getTemarioTrainers'),
        validarAprobacion: context.llamarServidorRespuesta('HomeVentas/validarAprobacion')
    };
};
export default ServiciosCatalogo;
