import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCurso = () => {
    const context = useContext(LayautContext);
    return {
        cursosInt: context.llamarServidor('Curso/getCursoInt'),
        insertCurso: context.llamarServidorRespuesta('Curso/insertCurso'),
        updateCurso: context.llamarServidorRespuesta('Curso/updateCurso'),
        getCursoCosto: context.llamarServidor('Curso/getCursoCosto'),
        insertCursoCosto: context.llamarServidorRespuesta('Curso/insertCursoCosto'),
        updateCursoCosto: context.llamarServidorRespuesta('Curso/updateCursoCosto'),
        cambiarEstatusCursoCosto: context.llamarServidorRespuesta('Curso/cambiarEstatusCursoCosto'),
        getsesionCurso: context.llamarServidor('Curso/getsesionCurso'),
        insertSession: context.llamarServidorRespuesta('Curso/insertSession'),
        insertSesionTrainer: context.llamarServidorRespuesta('Curso/insertSesionTrainer'),
        updateSesion: context.llamarServidorRespuesta('Curso/updateSesion'),
        cambiarEstatusSesionCurso: context.llamarServidorRespuesta('Curso/cambiarEstatusSesionCurso'),
        grupoInt: context.llamarServidor('Grupo/getGrupoInt'),
        getGruposSinAsignar: context.llamarServidor('Grupo/getGruposSinAsignar'),
        agregarGrupoCurso: context.llamarServidorRespuesta('Curso/agregarGrupoCurso'),
        guardarDependenciaSesion: context.llamarServidorRespuesta('Curso/guardarDependenciaSesion'),
        updateOrdenSesion: context.llamarServidorRespuesta('Curso/updateOrdenSesion'),
        borrarCurso: context.llamarServidorRespuesta('Curso/borrarCurso'),
        // Temario
        getTemarioSesion: context.llamarServidor('Curso/getTemarioSesion'),
        getTemarioTrainers: context.llamarServidor('Curso/getTemarioTrainers'),
        insertTemario: context.llamarServidorRespuesta('Curso/insertTemario'),
        updateTemario: context.llamarServidorRespuesta('Curso/updateTemario'),
        cambiarEstatusSesionTemario: context.llamarServidorRespuesta('Curso/cambiarEstatusSesionTemario'),
        guardarDependenciaTemario: context.llamarServidorRespuesta('Curso/guardarDependenciaTemario'),
        updateOrdenTemario: context.llamarServidorRespuesta('Curso/updateOrdenTemario'),
        // Actividad
        getActividadTema: context.llamarServidor('Actividad/getActividadTema'),
        insertActividad: context.llamarServidorRespuesta('Actividad/insertActividad'),
        updateActividad: context.llamarServidorRespuesta('Actividad/updateActividad'),
        cambiarEstatusActividad: context.llamarServidorRespuesta('Actividad/cambiarEstatusActividad'),
        updateOrdenActividad: context.llamarServidorRespuesta('Actividad/updateOrdenActividad'),
        guardarDependenciaActividad: context.llamarServidorRespuesta('Actividad/guardarDependenciaActividad'),
        // Detalle Actividad
        getDetalleActividad: context.llamarServidor('Actividad/getDetalleActividad'),
        insertDetalleActividad: context.llamarServidorRespuesta('Actividad/insertDetalleActividad'),
        updateActivivdadDetalle: context.llamarServidorRespuesta('Actividad/updateActivivdadDetalle'),
        getactividadDetalleExtra: context.llamarServidor('Actividad/getactividadDetalleExtra'),
        cambiarEstatusActividadDetatalle: context.llamarServidorRespuesta('Actividad/cambiarEstatusActividadDetatalle'),
        updateOrdenMaterial: context.llamarServidorRespuesta('Actividad/updateOrdenMaterial'),
        guardarDependenciaDetalle: context.llamarServidorRespuesta('Actividad/guardarDependenciaDetalle'),
        updateOrdenDetalleActividadExamen: context.llamarServidorRespuesta('Actividad/updateOrdenDetalleActividadExamen'),
        chunkFile: context.llamarServidorBigFile('ChunksHandler/chunckFile'),
        //  Preguntas Actividad
        getActividadPregunta: context.llamarServidor('Actividad/getActividadPregunta'),
        insertPreguntaActividad: context.llamarServidorRespuesta('Actividad/insertPreguntaActividad'),
        updatePreguntaActividad: context.llamarServidorRespuesta('Actividad/updatePreguntaActividad'),
        cambiarEstatusPregunta: context.llamarServidorRespuesta('Actividad/cambiarEstatusPregunta')
    };
};

export default ServiciosCurso;
