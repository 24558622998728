import React, { useEffect, useState } from 'react';
// material-ui
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowUp, StarBorder } from '@mui/icons-material';
import { Chip, Typography, Stack, Button, Grid, TextField, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Scrollbars } from 'react-custom-scrollbars';

// project imports
import Services from 'utils/serviciosVentas';
import CardCurso from './Cards/CardCurso';
import { width } from '@mui/system';

// ==============================|| DEFAULT DASHBOARD ||============================== //
const useStyle = makeStyles(() => ({
    chip: {
        backgroundColor: '#cabc93',
        color: '#FFFFFF',
        margin: '2px',
        '&:hover': { textDecoration: 'underline' }
    }
}));

const Inicio = (props) => {
    const { permiso, usuario, cursos, cursosMostrar, hasCursos, numCursos, clasificacion, tipoElegido } = props;
    const classes = useStyle();

    const services = Services();
    const theme = useTheme();

    const [listaCursos, setListaCursos] = useState([]);

    const filtraCurso = (item) => () => {
        if (item == 0) {
            setListaCursos([...cursosMostrar]);
        } else {
            let arrMostrar = cursosMostrar.filter((cur) => Number(cur.tipoCurso) == item);
            setListaCursos([...arrMostrar]);
        }

        // if (item == 0) {
        //     let filtrados = cursosMostrar.filter((cur) => {
        //         if (tipoElegido == 0) {
        //             return Number(cur.porcentajeCompletado) < 100;
        //         } else {
        //             return Number(cur.porcentajeCompletado) == 100;
        //         }
        //     });
        //     setListaCursos([...filtrados]);
        // } else {
        //     let filtrados = cursosMostrar.filter((cur) => {
        //         if (tipoElegido == 0) {
        //             return Number(cur.porcentajeCompletado) < 100 && Number(cur.tipoCurso) == item;
        //         } else {
        //             return Number(cur.porcentajeCompletado) == 100 && Number(cur.tipoCurso) == item;
        //         }
        //     });
        //     setListaCursos([...filtrados]);
        // }
    };

    const muestraNumero = (item) => {
        if (item == 0) {
            return cursosMostrar.length;
        } else {
            let filtrados = cursosMostrar.filter((cur) => {
                return Number(cur.tipoCurso) == item;
            });
            return filtrados.length;
        }
    };

    useEffect(() => {
        if (cursosMostrar) {
            // let filtrados = cursos.filter((cur) => Number(cur.porcentajeCompletado) < 100);
            setListaCursos([...cursosMostrar]);
        }
    }, [cursosMostrar]);

    return (
        <Grid
            container
            spacing={2}
            sx={{ overflowX: 'scroll', border: 'solid #dddddd 5px', borderRadius: '15px', backgroundColor: '#f9f9f9' }}
        >
            <Grid item xs={12}>
                <Chip className={classes.chip} onClick={filtraCurso(0)} label={`Todos los cursos (${muestraNumero(0)})`} size="medium" />

                <Chip
                    className={classes.chip}
                    onClick={filtraCurso(1)}
                    label={`Capacitaciones informativas organizacionales (${muestraNumero(1)})`}
                    size="medium"
                />
                <Chip
                    className={classes.chip}
                    onClick={filtraCurso(3)}
                    label={`Capacitaciones blandas (${muestraNumero(3)})`}
                    size="medium"
                />
                <Chip
                    className={classes.chip}
                    onClick={filtraCurso(4)}
                    label={`Capacitaciones técnicas (${muestraNumero(4)})`}
                    size="medium"
                />
                <Chip className={classes.chip} onClick={filtraCurso(2)} label={`Trainers Maderas (${muestraNumero(2)})`} size="medium" />
            </Grid>
            {hasCursos ? (
                <Grid item container spacing={1} xs={12} display="flex" alignItems="center" direction="row" wrap="nowrap">
                    {listaCursos.map((curso) => (
                        <Grid item key={`${curso.titulo}${curso.idCurso}`}>
                            <CardCurso curso={curso} {...props} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <>
                    <br></br>
                    <Typography variant="h2" align="center">
                        No te encuentras inscrito a algún curso
                    </Typography>
                </>
            )}
        </Grid>

        // <Carousel centerMode centerSlidePercentage={30} showStatus={false} showIndicators={false} showThumbs={false}>
        //     {cursos?.map((curso) => (
        //         <CardCurso key={`${curso.titulo}${curso.idCurso}`} curso={curso} {...props} />
        //     ))}
        //     {!hasCursos && (
        //         <>
        //             <br></br>
        //             <Typography variant="h2" align="center">
        //                 No te encuentras inscrito a algún curso
        //             </Typography>
        //         </>
        //     )}
        // </Carousel>
    );
};

export default Inicio;
