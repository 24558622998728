import React, { useState, useEffect } from 'react';
import ServiciosCatalogo from './services';
import Inicio from 'views/HomeParticipantes/Inicio';
import VistaCursoIncognito from './VistaCursoIncognito';
import { Backdrop, CircularProgress } from '@mui/material';

const DialogoCursoIncognito = ({ permisos, usuario, option, esIncognito }) => {
    const services = ServiciosCatalogo();
    // States
    const [arbolCursos, setArbolCursos] = useState({
        grupos: [],
        cursos: [],
        sesion: [],
        temario: [],
        calificacion: 0,
        aciertos: 0,
        desaciertos: 0,
        actividades: []
    });
    const [modulo, setModulo] = useState(0);
    const [colaboradores, setColaboradores] = useState([]);
    const [ranking, setRanking] = useState({ posicion: 0, topTen: [] });
    const [videoData, setVideoData] = useState({});
    const [contacto, setContacto] = useState([]);
    const [cargando, setCargando] = useState({ open: false });
    const [hasCursos, setHasCursos] = useState(true);
    // Functions
    const selectCurso = (idCurso) => {
        setModulo(idCurso);
        // let cursoEncontrado = arbolCursos.cursos.find((curso) => curso.idCurso == idCurso);
        // navigate('/vistaCurso', { state: { curso: cursoEncontrado } });
    };

    const unselectCurso = () => {
        setModulo(0);
    };

    useEffect(() => {
        setCargando({ open: true });
        services.Allcursos(
            (data) => {
                setArbolCursos({
                    ...arbolCursos,
                    cursos: data.cursos
                });
                if (data?.cursos?.length == 0) setHasCursos(false);
                setRanking(data.ranking);
                setContacto({
                    nombre: data.datosGenerales?.nasesor,
                    telefono: data.datosGenerales?.telefono,
                    correo: data.datosGenerales?.email,
                    depto: usuario.depto
                });
                if (data.datosGenerales) setVideoData({ linkDrop: data.datosGenerales.linkDrop });
                setCargando({ open: false });
            },
            { idColaborador: usuario.idColaborador, idGrupo: usuario.idGrupo, option, idContrato: usuario.idcontrato }
        );
    }, []);

    return (
        <>
            {modulo ? (
                <VistaCursoIncognito
                    esIncognito={esIncognito}
                    arbolCursos={arbolCursos}
                    setArbolCursos={setArbolCursos}
                    ranking={ranking}
                    info={contacto}
                    // colaboradores={colaboradores}
                    curso={arbolCursos.cursos.find((curso) => curso.idCurso == modulo)}
                    unselectCurso={unselectCurso}
                    permisos={permisos}
                    usuario={usuario}
                    option={option}
                />
            ) : (
                <Inicio
                    usuario={usuario}
                    cursos={arbolCursos.cursos}
                    selectCurso={selectCurso}
                    ranking={ranking}
                    info={contacto}
                    videoData={videoData}
                    hasCursos={hasCursos}
                />
            )}
            <Backdrop open={cargando.open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default DialogoCursoIncognito;
