import React from 'react';
import { Divider, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { GroupRadioButtonForm, SwitchForm, TextFieldNumber } from 'ui-component';
import NumberFormat from 'react-number-format';

const FormCostoGrupo = ({ open, isAdd, data, ctl, setCargando, setMensaje, handleClose, ...props }) => {
    const {
        register,
        watch,
        formState: { errors },
        control
    } = useFormContext();
    return (
        <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
                <TextFieldNumber
                    id="txt-num-cal"
                    label="Calificación"
                    name="calificacion"
                    defaultValue={data?.calificacion}
                    control={control}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                />
            </Grid>
        </Grid>
    );
};

export default FormCostoGrupo;
