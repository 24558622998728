import { Button, Grid, List, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ModalGeneral, VisorPDF } from 'ui-component';
import ServiciosCatalogo from '../Components/services';

const ListaProcesos = (props) => {
    const services = ServiciosCatalogo();
    const { usuario } = props;
    const useStyles = makeStyles((theme) => ({
        btnAceptar: {
            color: theme.palette.btn.green,
            '&:hover': {
                backgroundColor: theme.palette.btn.green,
                color: theme.palette.btn.white
            }
        },
        btnCancelar: {
            color: theme.palette.btn.red,
            '&:hover': {
                backgroundColor: theme.palette.btn.red,
                color: theme.palette.btn.white
            }
        }
    }));
    const theme = useTheme();
    const classes = useStyles();
    const [procesos, setProcesos] = useState([]);
    const [modal, setModal] = useState({ open: false, props: null, componente: null });
    const cerrarModal = () => {
        setModal({
            ...modal,
            open: false
        });
    };
    const abrePdf = (item) => (e) => {
        services.buscaArchivoCH(
            (res) => {
                if (res.archivo && res.archivo != null) {
                    setModal({
                        open: true,
                        titulo: `Proceso ${item.nombreProceso}`,
                        props: { data: { pdf: res.archivo }, isBase64: true },
                        componente: VisorPDF
                    });
                }
            },
            { ...item }
        );
    };

    useEffect(() => {
        services.buscaProcesosCH(
            (res) => {
                setProcesos(res.procesos);
            },
            { depto: usuario.depto, area: usuario.area }
        );
    }, [usuario]);
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {procesos && procesos.length > 0 ? (
                        <List sx={{ backgroundColor: theme.palette.background.default, p: 0 }}>
                            {procesos.map((pro) => (
                                <div key={`sesion${pro.codProceso}`}>
                                    <Button fullWidth sx={{ backgroundColor: theme.palette.primary.dark, m: 1 }} onClick={abrePdf(pro)}>
                                        {pro.nombreProceso}
                                    </Button>
                                </div>
                            ))}
                        </List>
                    ) : (
                        <Typography variant="body1" sx={{ m: 1 }}>
                            No existen procesos disponibles
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <ModalGeneral
                open={modal.open}
                titulo={modal.titulo}
                width="lg"
                acciones={
                    <Button onClick={cerrarModal} className={classes.btnCancelar}>
                        Cerrar
                    </Button>
                }
            >
                {modal.componente && <modal.componente {...modal.props} />}
            </ModalGeneral>
        </>
    );
};

export default ListaProcesos;
