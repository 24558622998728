import LayautContext from 'Context/ContextLayaut';
import { useContext } from 'react';

const ServiciosCatalogo = () => {
    const context = useContext(LayautContext);

    return {
        // Informacion principal
        getAsesores: context.llamarServidor('Trainer/getAsesores/'),
        ingresarPerfil: context.llamarServidor('Trainer/ingresarPerfil/'),
        // getInfoInicialVent: context.llamarServidor('IntEspecifica/getInfoInicialVent/'),

        // sendCorreoFormulario: context.llamarServidorRespuesta('IntEspecifica/sendCorreoFormulario/'),
        // sendCorreoReporte: context.llamarServidorRespuesta('IntEspecifica/sendCorreoReporte/')

        Allcursos: context.llamarServidor('HomeVentas/Allcursos')
    };
};

export default ServiciosCatalogo;
