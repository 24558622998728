import React, { useState, useEffect, useContext } from 'react';
import LayautContext from 'Context/ContextLayaut';
import { RestorePage } from '@mui/icons-material';
import {
    Grid,
    Typography,
    colors,
    Avatar,
    Card,
    CardContent,
    Divider,
    CardHeader,
    CardMedia,
    CircularProgress,
    Stack,
    Tooltip
} from '@mui/material';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import { styled, useTheme } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

import Services from 'utils/serviciosVentas';
import oro from 'assets/images/oro.png';
import plata from 'assets/images/plata.png';
import bronce from 'assets/images/bronce.png';
import rechazado from 'assets/images/rechazar.png';
import burrito from 'assets/images/burrito.png';
import { Box } from '@mui/system';
import { gridTabIndexColumnHeaderSelector } from '@mui/x-data-grid';
// import VistaExam from './VistaExam';
import VistaExam from '../../Calificaciones/components/OpcRegistro';
// ==============================|| DEFAULT DASHBOARD ||============================== //

const Item = styled(Typography)(({ theme }) => ({
    ...theme.typography.body1,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));
const useStyles = makeStyles((theme) => ({
    respuestaCorrecta: {
        background: theme.palette.success.main,
        border: 0,
        borderRadius: 3,
        color: 'white'
    },
    respuestaSeleccionada: {
        border: `3px solid ${theme.palette.info.main}`
    },
    cardHeader: {
        backgroundColor: theme.palette.warning.light
    }
}));

const style = {
    circularProgres: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
};

const Calificacion = ({ permisos, usuario, data, verActividadRespondida, reiniciarExamen }) => {
    const classes = useStyles();
    const theme = useTheme();
    const context = useContext(LayautContext);
    const califAprobatoria = parseInt(data.califDetAct, 10);
    const calificacion = parseInt(data?.calificacion, 10);
    const aciertos = parseInt(data?.aciertos, 10);
    const desaciertos = parseInt(data?.desaciertos, 10);
    const isAprobado = parseInt(data?.isAprobado, 10);
    const tipo = parseInt(data?.tipo, 10);
    const reinicios = parseInt(data?.reinicios, 10);
    const activoReprobado = parseInt(data?.activoReprobado, 10);
    const nintentos = parseInt(data?.nintentos, 10);

    const intentos = reinicios + 1;
    // const showReinicio = tipo == 3 && calificacion < califAprobatoria && reinicios < 2 && (activoReprobado >= 1 || isAprobado == 0);
    const [showReinicio, setShowReinicio] = useState(false);

    useEffect(() => {
        if (data) {
            if (Number(data.tipo) == 3) {
                if (data.numIntentos && Number(data.numIntentos) > 0) {
                    setShowReinicio(nintentos < Number(data.numIntentos));
                } else {
                    setShowReinicio(nintentos < 3);
                }
                // if (Number(data.tipoCurso) == 1) {
                //     setShowReinicio(nintentos < 3);
                // } else {
                //     const intentosTrainers = Number(data.numIntentos);
                //     setShowReinicio(intentosTrainers && intentosTrainers != null ? nintentos < intentosTrainers : nintentos < 3);
                // }
            } else {
                setShowReinicio(true);
            }
        }
    }, [data]);
    // Components
    const TipoC = (props) => {
        const image = props.isAprobado ? oro : rechazado;
        return <CardMedia image={image} sx={{ width: 200 }} component="img" />;
    };
    const PorcentajeCircular = ({ porcentaje, text, isPorcentaje, nombre, color }) => (
        <>
            <MineTooltip title={nombre}>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.warning.light,
                        color: theme.palette.warning.dark,
                        p: 4,
                        mb: 2
                    }}
                >
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress size={50} variant="determinate" color="warning" value={porcentaje} />
                        <Box sx={style.circularProgres}>
                            <Typography variant="caption" color="text.secondary">
                                {text}
                            </Typography>
                        </Box>
                    </Box>
                </Avatar>
            </MineTooltip>
        </>
    );

    const ItemPorcentaje = ({ porcentaje, text, isPorcentaje, nombre, color }) => (
        <>
            <MineTooltip title="AAA">
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.tallAvatar,
                        backgroundColor: theme.palette.primary.mine,
                        p: 4,
                        mb: 2
                    }}
                >
                    <Stack spacing={1} display="flex" justifyContent="space-between">
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress size={50} variant="determinate" color="success" value={50} />
                            <Box sx={style.circularProgres}>
                                <Typography variant="cardSubtitle">AAA</Typography>
                            </Box>
                        </Box>
                        <Typography variant="cardBody">AAA</Typography>
                    </Stack>
                </Avatar>
            </MineTooltip>
        </>
    );

    const MineTooltip = ({ title, children }) => (
        <Tooltip title={title} disableInteractive>
            {children}
        </Tooltip>
    );

    const columns = [
        { label: 'Pregunta', name: 'npregunta', filtrar: true },
        {
            label: 'Estatus',
            name: 'isnCorrecta',
            type: { opcion: 'etiqueta' },
            style: (row) => ({
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: row.isCorrecta == 1 ? colors.green[500] : colors.red[500]
            }),
            filtrar: {
                id: 'isCorrecta',
                value: 'isnCorrecta',
                ctl: [
                    { isCorrecta: '1', isnCorrecta: 'Correcta' },
                    { isCorrecta: '0', isnCorrecta: 'Incorrecta' }
                ]
            }
        }
    ];

    const handleinput = () => {
        context.formulario.abrir({
            open: true,
            titulo: 'Resultado de examen',
            width: 'md',
            propsForm: { data: data, columns: columns },
            formulario: VistaExam,
            isVer: true
        });
    };

    return (
        <Card style={{ height: '60vh', overflowY: 'auto' }}>
            {tipo == 3 ? (
                <CardHeader
                    style={{ textAlign: 'center' }}
                    title={`${isAprobado ? '¡FELICIDADES!' : 'NO ACREDITADO'}`}
                    subheader={`${
                        isAprobado
                            ? 'Has terminado la lección satisfactoriamente.'
                            : 'Aprender es un proceso. Intenta nuevamente la evaluación y sigue avanzando en tu aprendizaje'
                    }`}
                />
            ) : (
                <CardHeader
                    style={{ textAlign: 'center' }}
                    title={`${isAprobado ? 'ENCUESTA FINALIZADA' : ''}`}
                    subheader={`${isAprobado ? 'Gracias por aportar tus comentarios.' : ''}`}
                />
            )}

            <CardContent style={{ textAlign: 'center' }}>
                {![undefined, null, ''].includes(calificacion) && tipo == 3 ? (
                    <Grid container spacing={3} columns={12}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <TipoC isAprobado={isAprobado} />
                            </div>
                            <CardHeader
                                style={{ textAlign: 'center' }}
                                title={`${isAprobado ? 'Aprobado' : 'No Aprobado'}`}
                                subheader={`Puntuación: ${calificacion}%`}
                            />
                            {/* {isAprobado ? (
                                <Link href="#" onClick={() => handleinput(nintentos)} style={{ Color: colors.green[500] }}>
                                    Ver examen
                                </Link>
                            ) : (
                                ''
                            )} */}
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={2} direction="row" display="flex" justifyContent="space-between">
                                {/* <ItemPorcentaje /> */}
                                <PorcentajeCircular
                                    porcentaje={100}
                                    text={`${califAprobatoria}`}
                                    isPorcentaje
                                    nombre="Calificación para aprobar."
                                    color="primary"
                                />
                                <PorcentajeCircular
                                    porcentaje={calificacion}
                                    text={`${calificacion}%`}
                                    isPorcentaje
                                    nombre="Calificación del examen."
                                    color="primary"
                                />
                                <PorcentajeCircular
                                    porcentaje={(aciertos / (aciertos + desaciertos)) * 100}
                                    text={aciertos.toFixed(0)}
                                    nombre="Aciertos"
                                    color="success"
                                />
                                <PorcentajeCircular
                                    porcentaje={(desaciertos / (aciertos + desaciertos)) * 100}
                                    text={desaciertos.toFixed(0)}
                                    nombre="Desaciertos"
                                    color="error"
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                ) : null}
                <Divider />
                <Grid container sx={{ textAlign: 'center', mt: 2 }}>
                    <Grid item xs={10} display="flex" justifyContent="center">
                        {calificacion || tipo == 3 ? (
                            <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center' }}>
                                Los grandes conocimientos engendran las grandes dudas. &quot; -Aristóteles
                            </Typography>
                        ) : null}
                    </Grid>
                    <Grid item xs={1} display="flex" justifyContent="center">
                        {tipo == 3 && (
                            <MineTooltip title="Intentos">
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.largeAvatar,
                                        backgroundColor: theme.palette.warning.light,
                                        color: theme.palette.warning.dark
                                    }}
                                >
                                    {nintentos}
                                </Avatar>
                            </MineTooltip>
                        )}
                    </Grid>
                    <Grid item xs={1} display="flex" justifyContent="center">
                        {permisos?.reExa && showReinicio && (
                            <MineTooltip title={tipo == 3 ? 'Repetir examen' : 'Contestar nuevamente'}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.largeAvatar,
                                        backgroundColor: theme.palette.warning.light,
                                        color: theme.palette.warning.dark
                                    }}
                                    onClick={() => reiniciarExamen(data)}
                                >
                                    <RestorePage />
                                </Avatar>
                            </MineTooltip>
                        )}

                        {/* <MineTooltip title="Ver actividad">
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.largeAvatar,
                                    backgroundColor: theme.palette.warning.light,
                                    color: theme.palette.warning.dark
                                }}
                                onClick={verActividad}
                            >
                                <Task />
                            </Avatar>
                        </MineTooltip> */}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Calificacion;
