import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import constantes from 'utils/Constantes';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Alert,
    AppBar,
    Backdrop,
    Box,
    CircularProgress,
    CssBaseline,
    Snackbar,
    Toolbar,
    useMediaQuery,
    Typography,
    LinearProgress,
    colors
} from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import { CloudUpload } from '@mui/icons-material';
import LayautContext from 'Context/ContextLayaut';
// llamada servidor
import axios from 'axios';
import LoginContext from 'Context/ContextLogin';
import { makeStyles } from '@mui/styles';
import { ModalFormularioV2, AvisoConfirmacion } from 'ui-component';
import { width } from '@mui/system';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));
const useStyle = makeStyles((theme) => ({
    cargando: {
        zIndex: '1600'
    }
}));
// configuracion del axios
const iaxios = axios.create({
    baseURL: constantes.raiz
});
// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ rutas }) => {
    const theme = useTheme();
    const classes = useStyle();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const [cargando, setCargando] = useState(false);
    const [modalAddEditUser, setModalAddEditUser] = useState({
        open: false,
        titulo: '',
        isVer: false,
        mensajeConfirmacion: undefined,
        formulario: undefined,
        propsForm: null,
        width: 'xs',
        aceptar: () => {}
    });
    const [mensaje, setMensaje] = useState({
        open: false,
        status: 1,
        value: ''
    });
    const [confirmar, setConfirmar] = useState({
        open: false,
        titulo: '',
        text: '',
        aceptar: () => {},
        close: () => {}
    });
    const [progresoCarga, setProgresoCarga] = useState({ open: false, progreso: 0 });
    // context
    const context = useContext(LoginContext);

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    const cerrarMensaje = (event) => setMensaje({ ...mensaje, open: false });

    const cerrarModalForm = (event) => setModalAddEditUser({ ...modalAddEditUser, open: false });
    const cerrarModalConfirmar = (event) => setConfirmar({ open: false, titulo: '', text: '', aceptar: () => {}, close: () => {} });
    // configuracion del axios
    iaxios.defaults.headers.common = { authorization: context.login?.token };
    const llamarServidor = (ruta) => (setCtl, body) => {
        setCargando(true);
        iaxios
            .post(ruta, body)
            .then((response) => {
                setCtl(response.data);
                setCargando(false);
            })
            .catch((error) => {
                setCtl([]);
                console.log(error);
                setCargando(false);
            });
    };
    const llamarServidorRespuesta = (ruta) => (onSuccess, body, options) => {
        setCargando(options?.cargando != undefined ? options.cargando : true);
        iaxios
            .post(ruta, body, options ? options : {})
            .then((response) => {
                let mensaje = 'ERROR DESCONOCIDO';
                let status = -1;
                setCargando(false);
                if (options?.isBigFileResponse != undefined) setProgresoCarga({ open: false, progreso: 0 }); //linea progreso bigFile
                if (response.status === 200 && response.data.status != undefined) {
                    if (response.data.status > 0) {
                        onSuccess(response.data.data);
                    }
                    status = response.data.status;
                    mensaje = response.data.mensaje;
                }
                setMensaje({ ...mensaje, open: true, status: status, value: mensaje });
                if (options?.isBigFileResponse != undefined && options?.uidFolderName != undefined && status < 0)
                    // linea error llamada big file
                    iaxios.post('ChunksHandler/clearChunksRequest', { uidFolderName: options.uidFolderName }, {});
            })
            .catch((error) => {
                setMensaje({ ...mensaje, open: true, status: -1, value: 'Error de servidor' });
                setCargando(false);
                if (options?.isBigFileResponse != undefined && options?.uidFolderName != undefined)
                    // linea error llamada big file
                    iaxios.post('ChunksHandler/clearChunksRequest', { uidFolderName: options.uidFolderName }, {});
            });
    };
    const llamarServidorBigFile = (ruta) => (onSuccess, body, file) => {
        /* El tamaño debe cada pedazo(chunck) debe ser menor al maximo
         permitido por el servidor */
        const size = 1500000; //5388608 // el tamaño debe cada pedazo(chunck)
        let reader = new FileReader();
        let buf;
        let nombreOriginal = file.path || file.name;
        let uidFolderName = Date.now().toString(36) + Math.random().toString(36).substring(2);

        reader.onload = /*async*/ function (e) {
            buf = new Uint8Array(e.target.result);
            let chunksNumber = Math.ceil(buf.length / size);
            let counter = 1;
            let archivoConstruido = false;
            setProgresoCarga({ open: true, progreso: 0 });
            for (let i = 0; i < buf.length; i += size) {
                let fd = new FormData();
                fd.append('fname', 'p_' + counter);
                fd.append('file', new Blob([buf.subarray(i, i + size)]));
                fd.append('isLastChunk', 0);
                fd.append('totalDePedazos', chunksNumber);
                fd.append('numeroDePedazo', counter);
                fd.append('nombreOriginal', nombreOriginal);
                fd.append('tamanioOriginal', file.size);
                fd.append('uidFolderName', uidFolderName);
                counter++;
                let progreso = (counter * 80) / chunksNumber;

                /* await*/ iaxios
                    .post(ruta, fd, { headers: { 'content-type': 'multipart/form-data' } })
                    .then((response) => {
                        let mensaje = 'ERROR DESCONOCIDO';
                        let status = -1;
                        if (response.status === 200 && response.data.status != undefined) {
                            if (response.data.status > 0) {
                                if (response?.data?.construido == 1) {
                                    setProgresoCarga({ open: true, progreso: progreso });
                                    onSuccess(response.data.data, uidFolderName);
                                    archivoConstruido = true;
                                }
                            }
                            status = response.data.status;
                            mensaje = response.data.mensaje;
                        }
                        setProgresoCarga({ open: true, progreso: progreso });
                    })
                    .catch((error) => {
                        console.log('error big file', error);
                        setMensaje({ ...mensaje, open: true, status: -1, value: 'Error de servidor' });
                    });
            }
        };
        reader.readAsArrayBuffer(file);
    };

    function LinearProgressWithLabel(props) {
        const colorLoading = {
            color: colors.blue['600'],
            fontWeight: 'bold'
        };
        const colorLoadingSucces = {
            color: colors.green['200'],
            fontWeight: 'bold'
        };
        const colorBar = {
            backgroundColor: props?.value > 80 ? colors.green['200'] : colors.blue['200'],
            width: '200px'
        };
        let mensaje = props?.value > 80 ? 'cargando...' : 'subiendo...';
        mensaje = progresoCarga.open == true && progresoCarga.progreso == 0 ? 'preparando...' : mensaje;
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" style={colorBar} {...props} />
                </Box>
                <Box sx={{ minWidth: 100 }}>
                    <Typography variant="body2" style={props?.value > 80 ? colorLoadingSucces : colorLoading}>
                        {mensaje}
                        {props?.value > 0 && `${Math.round(props.value)}%`}
                    </Typography>
                </Box>
                {Math.round(props?.value) >= 100 ? setProgresoCarga({ open: false, progreso: 0 }) : null}
            </Box>
        );
    }
    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <LayautContext.Provider
            value={{
                llamarServidor,
                llamarServidorRespuesta,
                llamarServidorBigFile,
                formulario: { abrir: setModalAddEditUser, cerrar: cerrarModalForm, cerrarModalForm },
                mensaje: setMensaje,
                modal: { confirmar: setConfirmar, cerrar: cerrarModalConfirmar }
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* header */}
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: '#003764',
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                    }}
                >
                    <Toolbar sx={{ backgroundColor: '#003764' }}>
                        <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    </Toolbar>
                </AppBar>

                {/* drawer */}
                <Sidebar drawerOpen={leftDrawerOpened} rutas={rutas} drawerToggle={handleLeftDrawerToggle} />

                {/* main content */}
                <Main theme={theme} open={leftDrawerOpened}>
                    {/* breadcrumb */}
                    <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                    <Outlet />
                </Main>

                {/* importar ruedita de pantalla pricinpal */}
                {/* <Customization /> */}
            </Box>
            <Backdrop className={classes.cargando} open={cargando}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={mensaje.open}
                autoHideDuration={4000}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={cerrarMensaje}
            >
                <Alert
                    onClose={cerrarMensaje}
                    variant="filled"
                    severity={(mensaje.status < 0 && 'error') || (mensaje.status > 0 && 'success') || 'info'}
                >
                    {mensaje.value}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={progresoCarga.open} message="gg" key={'topCenter'}>
                <Box sx={{ width: '100%' }}>
                    <Alert variant="filled" icon={<CloudUpload />} style={{ backgroundColor: colors.grey['500'] }}>
                        <LinearProgressWithLabel value={progresoCarga.progreso} />
                    </Alert>
                </Box>
            </Snackbar>
            <ModalFormularioV2
                titulo={modalAddEditUser.titulo}
                open={modalAddEditUser.open}
                isVer={modalAddEditUser.isVer}
                mensajeConfirmacion={modalAddEditUser.mensajeConfirmacion}
                bloqueo={cargando}
                width={modalAddEditUser.width}
                OnCancelar={() => cerrarModalForm()}
                aceptar={modalAddEditUser.aceptar}
                btnAceptarName={modalAddEditUser?.btnAceptarName}
                btnCancelarName={modalAddEditUser?.btnCancelarName}
            >
                {modalAddEditUser.formulario && <modalAddEditUser.formulario {...modalAddEditUser.propsForm} />}
            </ModalFormularioV2>
            <AvisoConfirmacion
                open={confirmar.open}
                title={confirmar.title}
                text={confirmar.text}
                accept={confirmar.aceptar}
                cancel={() => cerrarModalConfirmar()}
            />
        </LayautContext.Provider>
    );
};

export default MainLayout;
