import React, { useState } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';

const MaterialDescargable = ({ permiso, usuario, data, onClickFinalizar }) => {
    const descargarArchivo = () => {
        window.open(data?.descargable?.linkVideo, '_blank', 'noreferrer');
    };

    const finalizar = () => {
        onClickFinalizar({
            tipo: data?.tipo,
            idDetalleActividad: data?.idDetalleActividad,
            idColaborador: data?.idColaborador,
            idActividad: data?.idActividad,
            idTemario: data?.idTemario,
            idSesion: data?.idSesion,
            idCurso: data?.idCurso
        });
    };

    return (
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h1" align="center" style={{ color: '#FFFF' }}>
                    Material descargable
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudDownload />}
                    color="primary"
                    onClick={descargarArchivo}
                    style={{ alignSelf: 'center' }}
                >
                    descargar material
                </Button>
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="end">
                <Button variant="contained" sx={{ backgroundColor: '#003764', color: '#FFFFFF', borderRadius: '0px' }} onClick={finalizar}>
                    Terminado
                </Button>
            </Grid>
        </Grid>
    );
};

export default MaterialDescargable;
