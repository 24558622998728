import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControlLabel,
    Radio,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Box,
    Chip,
    MenuItem,
    FormHelperText
} from '@mui/material';
import { useFormContext } from 'react-hook-form';

import TextFieldAutoComplete from 'ui-component/TextFieldAutoComplete';
import { FormInputImage, GroupRadioButtonForm, TextFieldNumber, AutocompleteForm } from 'ui-component';
import { makeStyles } from '@mui/styles';
import constantes from 'utils/Constantes';
import portadaGenerica from 'assets/images/PortadaGenerica.png';

const useStyles = makeStyles((theme) => ({
    inputFile: {
        display: 'none'
    },

    avatar: {
        border: `4px solid ${theme.palette.white}`,
        height: theme.spacing(20),
        width: '100%',
        position: 'relative'
    },

    div_foto: {
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        color: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '& .hidden-avatar': {
            display: 'none',
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.50)',
            color: 'white',
            zIndex: '99',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: '4px solid white',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '48px'
        },
        '&:hover .hidden-avatar': {
            display: 'flex'
        }
    }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
function getStyles(name, personName) {
    return {
        backgroundColor: personName.indexOf(name) === -1 ? '' : 'rgba(25, 118, 210, 0.08)'
    };
}
const ModalAddEditUser = ({ open, isAdd, data, ctl, setCargando, setMensaje, nullImage, handleClose, clasificacionCurso, ...props }) => {
    const classes = useStyles();
    const {
        register,
        watch,
        setValue,
        formState: { errors },
        control
    } = useFormContext();

    const permisosParaEditarArea = (area) => {
        let idAreas = props?.userInfo?.areas?.map((area) => area.idarea) || [];

        if (!idAreas.includes(area.idarea)) return true;
        else return false;
    };

    useEffect(() => {
        if (data?.areas) setValue('areas', data.areas);
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item container alignItems="center">
                <Grid item xs={6} sm={12}>
                    <FormInputImage
                        id="frm-inp-img"
                        control={control}
                        defaultValue={{ objeto: data?.portadaCurso ? data.portadaCurso : portadaGenerica }}
                        name="portadaCurso"
                        alt="cursoPortada"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="txt-titulo"
                    label="Título*"
                    size="small"
                    fullWidth
                    {...register('titulo', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                    })}
                    error={Boolean(errors.titulo)}
                    helperText={errors.titulo?.message}
                    defaultValue={data?.titulo}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="txt-subtit"
                    label="Descripción*"
                    size="small"
                    fullWidth
                    {...register('subtitulo', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (value) => value.match(/(^\s+|\s+$)/g) == null || 'El inicio o el final no pueden ser espacios'
                    })}
                    error={Boolean(errors.subtitulo)}
                    helperText={errors.subtitulo?.message}
                    defaultValue={data?.subtitulo}
                />
            </Grid>
            {/* <Grid item xs={6} md={4}>
                <TextFieldNumber
                    id="txt-num-cost"
                    label="Costo*"
                    name="costo"
                    error={errors?.costo?.message}
                    helperText={errors.costo?.message}
                    defaultValue={data?.costo}
                    control={control}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    prefix="$"
                />
            </Grid> */}
            {/* <Grid item xs={6} md={4}>
                <TextFieldNumber
                    id="txt-num-niv"
                    label="Nivel requerido*"
                    name="nivelRequerido"
                    error={errors?.nivelRequerido?.message}
                    helperText={errors.nivelRequerido?.message}
                    defaultValue={data?.nivelRequerido}
                    control={control}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                />
            </Grid> */}
            {/* <Grid item xs={6} md={4}>
                <TextFieldNumber
                    id="txt-num-dur"
                    label="Duración *"
                    name="duracionCurso"
                    error={errors?.duracionCurso?.message}
                    helperText={errors.duracionCurso?.message}
                    defaultValue={data?.duracionCurso}
                    control={control}
                    suffix={` ${watch('duracionCurso')?.split(' ')[0] === '1' ? 'hora' : 'horas'}`}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                />
            </Grid> */}
            {/* <Grid item xs={6} md={4}>
                <TextFieldNumber
                    id="txt-num-cal"
                    label="Calificación aprobatoria*"
                    name="califAprobatoria"
                    error={errors?.califAprobatoria?.message}
                    helperText={errors.califAprobatoria?.message}
                    defaultValue={data?.califAprobatoria}
                    control={control}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    suffix="%"
                />
            </Grid> */}
            {/* <Grid item xs={8}>
                <TextFieldAutoComplete
                    id="txt-opt-est"
                    label="Estatus*"
                    control={control}
                    name="estatusCurso"
                    {...register('estatusCurso')}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    options={ctl.estatusCurso}
                    optionSelected={{ label: 'nCursoEstatus', id: 'idCursoEstatus' }}
                    error={errors.estatusCurso?.message}
                    defaultValue={data?.estatusCurso}
                    onChange={(estatusCurso) => setValue('estatusCurso', estatusCurso)}
                />
            </Grid> */}
            {/* <Grid item xs={12}>
                <FormControl sx={{ m: 0, width: '100%' }}>
                    <InputLabel id="areas-label">Áreas *</InputLabel>
                    <Select
                        labelId="areas-label"
                        id="select-multiple-areas"
                        multiple
                        value={watch('areas') ? watch('areas') : data?.areas || []}
                        onChange={(event) => setValue('areas', event.target.value)}
                        input={
                            <OutlinedInput
                                id="select-multiple-areas-chip"
                                label="Áreas*"
                                name="areasTxt"
                                {...register('areasTxt', { required: { value: true, message: 'Campo requerido' } })}
                                error={Boolean(errors.areasTxt)}
                                helperText={errors?.areasTxt}
                            />
                        }
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected?.map((value) => (
                                    <Chip key={value.idarea} label={value.narea} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {ctl?.areas?.map((area) => (
                            <MenuItem
                                key={area.idarea}
                                value={area}
                                style={getStyles(area, watch('areas') || [])}
                                disabled={permisosParaEditarArea(area)}
                            >
                                {area.narea}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{ color: '#f44336' }}>{errors?.areasTxt?.message}</FormHelperText>
                </FormControl>
            </Grid> */}
            {/* <Grid item xs={6} md={4}>
                <GroupRadioButtonForm
                    id="frm-btn-obli"
                    control={control}
                    name="obligatorio"
                    label="Obligatorio"
                    defaultValue={data?.obligatorio}
                    rules={{}}
                >
                    <FormControlLabel value="0" control={<Radio />} label="No" />
                    <FormControlLabel value="1" control={<Radio />} label="Sí" />
                </GroupRadioButtonForm>
            </Grid> */}
            {/* <Grid item xs={6} md={4}>
                <GroupRadioButtonForm
                    id="frm-btn-inter"
                    control={control}
                    name="interno"
                    label="Interno"
                    defaultValue={data?.interno}
                    rules={{}}
                >
                    <FormControlLabel value="0" control={<Radio />} label="No" />
                    <FormControlLabel value="1" control={<Radio />} label="Sí" />
                </GroupRadioButtonForm>
            </Grid> */}
            {/* <Grid item xs={6} md={4}>
                <GroupRadioButtonForm
                    id="frm-btn-ext"
                    control={control}
                    name="extemporaneo"
                    label="Extemporáneo"
                    defaultValue={data?.extemporaneo}
                    rules={{}}
                >
                    <FormControlLabel value="0" control={<Radio />} label="No" />
                    <FormControlLabel value="1" control={<Radio />} label="Sí" />
                </GroupRadioButtonForm>
            </Grid> */}
            <Grid item xs={12}>
                <AutocompleteForm
                    id="selectClasificacionCurso"
                    label="Clasificación*"
                    name="clasificacionCurso"
                    control={control}
                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                    options={clasificacionCurso}
                    defaultValue={data?.tipoCurso ? clasificacionCurso.find((x) => x.idClasificacionCurso == data?.tipoCurso) : null}
                    optionSelected={{ label: 'nClasificacioncurso', id: 'idClasificacionCurso' }}
                    error={errors?.clasificacionCurso?.message}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="txt-obj"
                    label="Objetivo"
                    size="small"
                    fullWidth
                    {...register('objetivo')}
                    // error={Boolean(errors.objetivo)}
                    // helperText={errors.objetivo?.message}
                    defaultValue={data?.objetivo}
                    multiline
                />
            </Grid>
            {/* <Grid item xs={12}>
                <TextField
                    id="txt-desc"
                    label="Descripción*"
                    size="small"
                    fullWidth
                    {...register('descripcion', { required: { value: true, message: 'Campo requerido' } })}
                    error={Boolean(errors.descripcion)}
                    helperText={errors.descripcion?.message}
                    defaultValue={data?.descripcion}
                    multiline
                />
            </Grid> */}
        </Grid>
    );
};

export default ModalAddEditUser;
