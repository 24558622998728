import React, { useEffect, useState, useContext } from 'react';
// material-ui

import { Box, Grid, Typography, Button } from '@mui/material';
import { Player, BigPlayButton } from 'video-react';
// project imports
import { gridSpacing } from 'store/constant';

import Services from 'views/Configuracion/Components/Services';
import MainCard from 'ui-component/cards/MainCard';
import { FormInputVideo } from 'ui-component';
import { useFormContext } from 'react-hook-form';
import LayautContext from 'Context/ContextLayaut';

// ==============================|| FormVideoV2       ||==============================

const FormVideoV2 = ({ defaultItem }) => {
    const {
        register,
        watch,
        setValue,
        getValues,
        formState: { errors },
        control
    } = useFormContext();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormInputVideo
                    id="frm-inp-img"
                    defaultValue={defaultItem?.portadaCurso ? getValues('portadaCurso.objectURL') : null}
                    control={control}
                    name="portadaCurso"
                    alt="cursoPortada"
                />
            </Grid>
        </Grid>
    );
};

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Configuracion = (props) => {
    const { permisos, usuario } = props;
    const context = useContext(LayautContext);
    const services = Services();
    // States
    // const [panelAlumno, setPanelAlumno] = useState({ banners: [], videos: [] });
    const [datosVideo, setDatosVideo] = useState(null);
    const [ctls, setCtls] = useState({
        departamentos: [],
        asesores: []
    });

    // Services
    const getPanelAlumno = () => {
        services.getPanelAlumnoV2((res) => {
            // let idsDepartamentosExistentes = res.deptosExistentes?.map((departamento) => departamento.iddepto) || [];
            setDatosVideo(res.video);
            // setCtls({ departamentos: res.deptos, departamentosExistentes: idsDepartamentosExistentes });
        });
    };

    const abreNueoVideo = () => {
        context.formulario.abrir({
            open: true,
            titulo: 'Actualizar video de presentación',
            width: 'sm',
            formulario: FormVideoV2,
            propsForm: { defaultItem: { portadaCurso: datosVideo.portadaPanel } },
            aceptar: (data) => {
                const formData = new FormData();
                const dataFormAuxiliar = { ...data };
                formData.append('idEditar', datosVideo.idConfVideoInforma);
                formData.append('portadaCurso', dataFormAuxiliar.portadaCurso?.file || 0);
                if (dataFormAuxiliar?.portadaCurso?.size > 1900000) {
                    const nombreOriginal = dataFormAuxiliar.portadaCurso.path;
                    formData.delete('portadaCurso');
                    services.chunkFile(
                        (res, uidFolderName) => {
                            formData.append('portadaCurso', uidFolderName + '/' + nombreOriginal);
                            formData.append('uidFolderName', uidFolderName);
                            services.updateVideoV2(
                                (res) => {
                                    setDatosVideo(res);
                                    context.formulario.cerrar();
                                },
                                formData,
                                { cargando: false, isBigFileResponse: true, uidFolderName: uidFolderName }
                            );
                        },
                        {},
                        dataFormAuxiliar.archivo
                    );
                    context.formulario.cerrar();
                } else {
                    services.updateVideoV2(
                        (res) => {
                            setDatosVideo(res);
                            context.formulario.cerrar();
                        },
                        formData,
                        {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }
                    );
                }
            }
        });
    };

    useEffect(() => {
        getPanelAlumno();
    }, []);

    // const propsAll = {
    //     panelAlumno,
    //     setPanelAlumno,
    //     ctls,
    //     setCtls
    // };

    return (
        <MainCard title="Panel de usuario">
            <Grid container spacing={gridSpacing} alignContent="center" alignItems="center" justifyContent="center">
                {/* <Grid item xs={12}>
                    <Videos {...props} {...propsAll} />
                </Grid> */}
                <Grid item md={6} xs={12}>
                    {datosVideo != null && datosVideo.portadaPanel != null ? (
                        <Player playsInline src={datosVideo.portadaPanel}>
                            <BigPlayButton position="center" />
                        </Player>
                    ) : (
                        <Typography variant="h4">No hay video de bienvenida asignado</Typography>
                    )}
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                    container
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                >
                    <Typography variant="h2" sx={{ m: '5px' }}>
                        Este es el video de bienvenida que se muestra a los colaboradores al momento de ingresar a la plataforma.
                    </Typography>
                    <Button sx={{ m: '5px' }} variant="contained" size="large" onClick={abreNueoVideo}>
                        Actualizar video
                    </Button>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default Configuracion;
