import React, { useState, useContext, useEffect } from 'react';
import { CloudUpload } from '@mui/icons-material';
import { Grid, Typography, Button, Divider, Box } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Calificacion, Lectura, Video, Examen, MaterialDescargable } from 'views/HomeParticipantes/ComponentsHomeVentas';
import FormEvidencia from './FormEvidencia';
import { VisorPDF } from 'ui-component';
import LayautContext from 'Context/ContextLayaut';
import Services from '../Components/services';
import Imagen from './Imagen';
import ActPdf from './ActPdf';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const useStyles = makeStyles((theme) => ({
    curso: {
        backgroundColor: theme.palette.secondary[800]
    },
    grupo: {
        borderRadius: '8px',
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary[800]
    }
}));

const Actividad = (props) => {
    const { breadcrumbs, data, regresar } = props;
    // console.log('data act', data);
    const services = Services();
    const context = useContext(LayautContext);
    const classes = useStyles();
    const theme = useTheme();
    // const arrBradcrumbs = breadcrumbs?.split('|,');
    // const collapse = [cambiarOpenP, cambiarOpenS, cambiarOpenT, cambiarOpenA];
    // const prefix = ['curso', 'sesion', 'temario', 'actividades'];

    const abrirFormEvidencia = (data) => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: 'Evidencia de material ' + data.titulo,
            btnAceptarName: 'Guardar',
            propsForm: { data: { ...data?.infoGeneral, idColaborador: data?.idColaborador } || {} },
            formulario: FormEvidencia,
            aceptar: subirEvidencia(data)
        });
    };

    const subirEvidencia = (data) => (dataForm) => {
        const dataFormPivote = { ...dataForm };
        const formData = new FormData();
        /* Verificamos si la evidencia se va a actualizar para llamar al servicio
         correspondiente segun sea el caso */
        const servicioRespuestaActividad = dataForm.esUpdate
            ? services.updateRespuestaActividadEntregable
            : services.insertRespuestaActividadEntregable;

        if (dataForm.archivo && dataForm?.archivo?.length > 0) dataFormPivote.archivo = dataForm.archivo[0];
        else return context.formulario.cerrar();

        formData.append('idDetalleActividad', data.idDetalleActividad);
        formData.append('idColaborador', data.idColaborador);
        formData.append('idActividad', data.idActividad);
        formData.append('idTemario', data.idTemario);
        formData.append('idTemario', data.idTemario);
        formData.append('idSesion', data.idSesion);
        formData.append('idCurso', data.idCurso);
        formData.append('idTipoActividad', data.infoGeneral.idTipoActividad);

        /* si el tamaño del archivo supera el maximo permitido por el servidor (2 Mb)
            el archivo se envia por pedazos (chunck file)
        */
        if (dataFormPivote?.archivo?.size > 1900000) {
            const nombreOriginal = dataFormPivote.archivo.path || dataFormPivote.archivo.name;
            formData.delete('archivo');
            services.chunkFile(
                (res, uidFolderName) => {
                    formData.append('archivo', uidFolderName + '/' + nombreOriginal);
                    formData.append('uidFolderName', uidFolderName);
                    servicioRespuestaActividad(
                        (res) => {
                            context.formulario.cerrar();
                        },
                        formData,
                        { cargando: false, isBigFileResponse: true, uidFolderName: uidFolderName }
                    );
                },
                {},
                dataFormPivote.archivo
            );
            context.formulario.cerrar();
        } else {
            formData.append('archivo', dataFormPivote.archivo);
            servicioRespuestaActividad(
                (res) => {
                    context.formulario.cerrar();
                },
                formData,
                {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
            );
        }
    };

    const buscaEsIncognito = (datos) => {
        // if (datos) {
        //     if (
        //         datos.descargable != false ||
        //         datos.examen != false ||
        //         datos.imagen != false ||
        //         datos.lectura != false ||
        //         datos.pdf != false ||
        //         datos.video != false
        //     ) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // } else {
        //     return false;
        // }
        if (datos && datos == true) {
            return true;
        } else {
            return false;
        }
    };

    // useEffect(() => {
    //     if (data) {
    //         console.log('llega actividad', data);
    //     }
    // }, [data]);
    return (
        <Grid sx={{ backgroundColor: '#ccbe95', borderRadius: '10px', padding: '2px' }} container spacing={2}>
            {/* <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb label="Inicio" icon={<Bookmark fontSize="small" />} onClick={() => regresar()} />
                    {arrBradcrumbs.map((bread, key) => {
                        const crumb = bread?.split('^~');
                        const id = crumb[0];
                        const titulo = crumb[1];

                        // Necesita los prefijos para saber el id seleccionado
                        return key < 4 ? (
                            <StyledBreadcrumb
                                key={`${id}-${titulo}`}
                                label={titulo}
                                icon={<Bookmark fontSize="small" />}
                                onClick={() => collapse[key](`${prefix[key]}${id}`)}
                            />
                        ) : (
                            <StyledBreadcrumb key={`${id}-${titulo}`} label={titulo} icon={<Star fontSize="small" />} />
                        );
                    })}
                </Breadcrumbs>
            </Grid> */}
            <Grid sx={{ backgroundColor: '#ccbe95' }} item xs={6}>
                <Box sx={{ ml: 2 }}>
                    <Typography variant="menuHeader" sx={{ color: '#FFFFFF' }}>
                        {data?.titulo}
                    </Typography>
                </Box>
            </Grid>
            {Number(data?.infoGeneral?.esEntregable) &&
                data?.infoGeneral?.solicitarEvidencia == 1 &&
                !buscaEsIncognito(props.esIncognito) == true && (
                    <Grid sx={{ backgroundColor: '#ccbe95' }} xs={6} justifyContent="flex-end">
                        <Button
                            id="btn-evid"
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUpload />}
                            color="success"
                            onClick={() => abrirFormEvidencia(data)}
                            style={{ marginBottom: '10px', marginTop: '10px' }}
                        >
                            Subir evidencia
                        </Button>
                    </Grid>
                )}
            <Grid item xs={12}>
                <Divider variant="fullWidth" sx={{ borderColor: '#a09e99' }} />
            </Grid>
            <Grid sx={{ m: 2 }} item xs={12}>
                <Box>
                    {data?.showResultado && [3, 7].includes(Number(data?.infoGeneral?.idTipoActividad)) && <Calificacion {...props} />}
                    {data?.lectura && <Lectura {...props} />}
                    {data?.video && <Video {...props} />}
                    {data?.examen && !data?.showResultado && <Examen {...props} />}
                    {data?.encuesta && !data?.showResultado && <Examen {...props} />}
                    {data?.pdf && <ActPdf {...props} />}
                    {data?.imagen && <Imagen {...props} />}
                    {data?.descargable && <MaterialDescargable {...props} />}
                </Box>
            </Grid>
        </Grid>
    );
};

export default Actividad;
