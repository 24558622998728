// Settings Context - src/context/Settings
import { Backdrop, CircularProgress, Snackbar, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';

const useStyle = makeStyles(() => ({
    cargando: {
        zIndex: '900'
    }
}));
const LoginContext = React.createContext();

const Loginettings = localStorage.getItem('token') != '' ? { token: localStorage.getItem('token'), usuario: { areas: [] } } : false;

export const LoginProvider = ({ children, loginDefalult }) => {
    const classes = useStyle();
    const [login, setLogin] = useState(loginDefalult || Loginettings);
    const [cargando, setCargando] = useState(false);
    const [mensaje, setMensaje] = useState({ open: false, status: 0, value: '' });
    const cerrarMensaje = (event) => setMensaje({ ...mensaje, open: false });
    const cambioLogin = (values) => {
        setLogin(values);
    };
    const cerrarLogin = () => {
        localStorage.clear();
        setLogin(null);
        window.location = '/';
    };
    return (
        <>
            <Backdrop className={classes.cargando} open={cargando}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <LoginContext.Provider value={{ login: login, cambioLogin, cerrarLogin, bloqueo: setCargando, mensaje: setMensaje }}>
                {children}
            </LoginContext.Provider>
            <Snackbar open={mensaje.open} autoHideDuration={4000} onClose={cerrarMensaje}>
                <Alert
                    onClose={cerrarMensaje}
                    variant="filled"
                    severity={(mensaje.status < 0 && 'error') || (mensaje.status > 0 && 'success') || 'info'}
                >
                    {mensaje.value}
                </Alert>
            </Snackbar>
        </>
    );
};

export const Loginconsumer = LoginContext.Consumer;
export const useLogin = () => {
    const { login } = useContext(LoginContext);

    return { usuario: login?.usuario || {} };
};

export default LoginContext;
