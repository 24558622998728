import { CheckBox } from '@mui/icons-material';
import { FormControlLabel, Grid, TextField, Checkbox, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Tabla } from 'ui-component';

const FormCorreoContacto = (props) => {
    const {
        register,
        setValue,
        watch,
        formState: { errors },
        control
    } = useFormContext();
    const [checked, setChecked] = useState(false);
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    const { deptos, defaultItem } = props;

    const [deptosTabla, setDeptosTabla] = useState([]);

    let deptosSelected = [];

    const columnas = [{ label: 'Área', name: 'ndepartamento', filtrar: true }];

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        // if (deptos) {
        //     setDeptosTabla(deptos);
        // }
        register('deptosElegidos');
        setValue('deptosElegidos', []);
        deptosSelected = [];

        if (defaultItem && defaultItem != null && defaultItem.ids != null) {
            setChecked(true);
            let arrIds = defaultItem.ids.split(',');

            let elegidos = [];

            let mostrar = [];
            deptos.forEach((element) => {
                if (arrIds.includes(element.iddepto)) {
                    element.isSeleccionado = true;
                    elegidos.push(element);
                    mostrar.push(element);
                } else {
                    element.isSeleccionado = false;
                    mostrar.push(element);
                }
            });

            deptosSelected = elegidos;
            setValue('deptosElegidos', elegidos);
            setDeptosTabla(mostrar);
        } else {
            setDeptosTabla(deptos);
        }
    }, [deptos, defaultItem]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <TextField
                    fullWidth
                    id="txt-correo"
                    label="Correo electrónico"
                    defaultValue={defaultItem?.correo}
                    error={errors.correo}
                    helperText={errors.correo ? errors.correo.message : ''}
                    {...register('correo', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (correo) => (correo && emailRegex.test(correo)) || 'Formato incorrecto'
                    })}
                />
                {!checked && (
                    <Typography variant="body1">
                        Si no se seleccionan departamentos, este correo será visible para todos los colaboradores en la pestaña de Contacto.
                    </Typography>
                )}
            </Grid>
            <Grid item xs={3}>
                <FormControlLabel
                    label="Asignar a departamentos"
                    control={<Checkbox checked={checked} onChange={handleChange} name="checkedB" color="secondary" />}
                />
            </Grid>
            {checked && (
                <Grid item xs={12}>
                    {errors.deptosElegidos && (
                        <span style={{ color: 'red' }} role="alert">
                            No hay departamentos seleccionados
                        </span>
                    )}
                    <Typography variant="body1">
                        Los Trainers de los departamentos seleccionados podrán visualizar este correo de contacto.
                    </Typography>
                    <Controller
                        control={control}
                        name="deptosElegidos"
                        rules={{ required: true }}
                        render={({ field: { onChange } }) => (
                            <Tabla
                                rows={deptosTabla || []}
                                columns={columnas}
                                isSeleccionable={{
                                    accion: (data, checked) => {
                                        let ajustados = watch('deptosElegidos');
                                        if (checked) {
                                            ajustados = [...ajustados, data];
                                            // ajustaTablaElegidos(data, true);
                                        } else {
                                            ajustados = ajustados.filter((grupo) => grupo.iddepto != data.iddepto);
                                            // ajustaTablaElegidos(data, false);
                                        }
                                        onChange(ajustados);
                                    },
                                    accionAll: (checked) => {
                                        deptosSelected = checked ? deptosTabla : [];
                                        onChange(deptosSelected);
                                    }
                                }}
                                columnKey="iddepto"
                            />
                        )}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default FormCorreoContacto;
