import React, { useState, useEffect, useContext } from 'react';

import MainCard from 'ui-component/cards/MainCard';
import LayautContext from 'Context/ContextLayaut';

import { Autocomplete, Breadcrumbs, Collapse, TextField, Typography } from '@mui/material';
import CursosTrainers from './components/CursosTrainers';
import Sesion from '../Cursos/Sesion';
import Temario from '../Cursos/Temario';
import Actividad from '../Cursos/Actividad';
import DetalleActividad from '../Cursos/DetalleActividad';
import { ServiciosCurso } from '../Cursos/components';

const niveles = [
    'Cursos Trainers Maderas',
    'Sesiones',
    // 'Temarios',
    'Actividades',
    'Material de actividad'
];
const CursosTrainerMaderas = ({ permisos, ...props }) => {
    const {
        usuario: { areas }
    } = props;

    const serviciosCursos = ServiciosCurso();

    //
    const context = useContext(LayautContext);
    const [rutaActiva, setRutaActiva] = useState([{ nivel: 0, value: null, id: null, nameLabel: '' }]);
    // States

    const [curso, setCurso] = useState(null);
    const [ctl, setCtl] = useState({ estatusCurso: [] });

    const actualizarRuta = (nivel, rutaCambiar) => {
        if (nivel >= 0) {
            rutaActiva.splice(nivel, rutaActiva.length - nivel, rutaCambiar);
            rutaActiva.push({ nivel: nivel + 1, modificar: !rutaActiva.some((ruta) => !ruta.modificar) });
            setRutaActiva([...rutaActiva]);
        } else {
            setRutaActiva([{ nivel: 0, modificar: true, value: null, id: null, nameLabel: '' }]);
        }
    };

    const cambiarNivel = (nivel) => (data, ctl) => {
        let ruta = {};
        switch (nivel) {
            case 0:
                ruta = { nivel: 0, value: data, modificar: true, id: 'idCurso', nameLabel: 'titulo', ctl: ctl };
                break;
            case 1:
                //consultaTemas(data);// podemos usar la version anterior, y podemos aplicar la bandera para enviar el curso y tener el tema con orden 1
                setCurso(data);
                ruta = { nivel: 1, value: data, modificar: data.estatus == '1', id: 'idSesion', nameLabel: 'titulo', ctl: ctl };
                break;
            // case 2:
            //     ruta = { nivel: 2, value: data, modificar: data.estatus == '1', id: 'idTemario', nameLabel: 'titulo', ctl: ctl };
            //     break;
            case 2:
                ruta = { nivel: 2, value: data, modificar: data.estatus == '1', id: 'idActividad', nameLabel: 'titulo', ctl: ctl };
                break;
            default:
                break;
        }

        actualizarRuta(nivel, ruta);
        // if (nivel != 1) {
        //     actualizarRuta(nivel, ruta);
        // }
    };
    const cambioOpcionNivel = (ruta) => (event, value) => {
        if (value) {
            if (ruta.nivel == 1) {
                setCurso(value);
            }
            ruta.value = value;
            ruta.modificar = ruta.nivel == 0 || value.estatus == '1';
            actualizarRuta(ruta.nivel, ruta);
        } else actualizarRuta(ruta.nivel - 1, rutaActiva[ruta.nivel - 1]);
    };
    //useEffect(() => console.log('rutaActiva', rutaActiva), [rutaActiva]);
    const ultimoNivel = rutaActiva[rutaActiva.length - 1].nivel;
    permisos = {
        ...permisos,
        modificar: rutaActiva[rutaActiva.length - 1].modificar
    };

    return (
        <MainCard
            title={
                <Breadcrumbs>
                    {rutaActiva.map((ruta, index) =>
                        index !== rutaActiva.length - 1 ? (
                            <Autocomplete
                                id="comp-autp"
                                key={`nivel_${index}`}
                                options={ruta.ctl}
                                size="small"
                                value={ruta.value}
                                getOptionLabel={(option) => option[ruta.nameLabel]}
                                defaultValue={ruta.value}
                                onChange={cambioOpcionNivel(ruta)}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="txt-niv-rut"
                                        label={niveles[ruta.nivel]}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: {
                                                width: `${Math.max(ruta.value[ruta.nameLabel]?.length, niveles[ruta.nivel].length) * 8}px`
                                            }
                                        }}
                                    />
                                )}
                            />
                        ) : (
                            <Typography variant="title">{niveles[ruta.nivel]}</Typography>
                        )
                    )}
                </Breadcrumbs>
            }
        >
            <Collapse in={ultimoNivel === 0}>
                <CursosTrainers permisos={permisos} onCambioNivel={cambiarNivel(0)} areas={areas} {...props} />
            </Collapse>
            <Collapse in={ultimoNivel === 1}>
                <Sesion
                    permisos={permisos}
                    onCambioNivel={cambiarNivel(1)}
                    esTrainers={true}
                    curso={ultimoNivel === 1 ? rutaActiva[ultimoNivel - 1].value : null}
                />
            </Collapse>
            {/* <Collapse in={ultimoNivel === 2}>
                <Temario
                    permisos={permisos}
                    onCambioNivel={cambiarNivel(2)}
                    sesion={ultimoNivel === 2 ? rutaActiva[ultimoNivel - 1].value : null}
                />
            </Collapse> */}
            <Collapse in={ultimoNivel === 2}>
                <Actividad permisos={permisos} onCambioNivel={cambiarNivel(2)} tema={null} curso={curso} />
            </Collapse>
            <Collapse in={ultimoNivel === 3}>
                <DetalleActividad permisos={permisos} actividad={ultimoNivel === 3 ? rutaActiva[ultimoNivel - 1].value : null} />
            </Collapse>
        </MainCard>
    );
};

export default CursosTrainerMaderas;
