import React, { useState, useEffect, useContext } from 'react';
import { AddBox, Reorder } from '@mui/icons-material';

import { ModalGeneral, Tabla } from 'ui-component';
import headers from './components/headers';
import LayautContext from 'Context/ContextLayaut';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import { ServiciosCurso } from './components';
import FormActividad from './components/FormActividad';
import FormOrdenar from './components/FormOrdenar';
import FormDependenciaActividad from './components/FormDependenciaActividad';

const Actividad = ({ permisos, tema, onCambioNivel, curso }) => {
    //
    const context = useContext(LayautContext);
    const serviciosCursos = ServiciosCurso();

    const [temaActual, setTemaActual] = useState(null);
    const [actividad, setActividad] = useState([]);
    const [ctl, setCtl] = useState({ estatusCurso: [] });
    const [confirm, setConfirm] = useState({ open: false });
    const [modal, setModal] = useState({ open: false, titulo: '' });
    // Handle
    const cerrarModalCurso = () => setModal({ ...actividad, open: false });
    const actualizarData = (nuevaCurso = []) => {
        const datausuario = actividad.map((oc) => oc.idActividad);
        nuevaCurso.forEach((actividadI) => {
            const indice = datausuario.indexOf(actividadI.idActividad);
            if (indice >= 0) actividad.splice(indice, 1, actividadI);
            else actividad.splice(0, 0, actividadI);
        });
        setActividad([...actividad]);
    };

    const actualizarDataV2 = (arr) => {
        let nuevoDato = arr[0];
        let arrActual = actividad;

        let indexActual = arrActual.findIndex((x) => x.idActividad == nuevoDato.idActividad);

        if (indexActual != -1) {
            arrActual[indexActual] = nuevoDato;
            setActividad([...arrActual]);
        }
    };

    const quitaData = (nuevoDato) => {
        let arrActual = actividad;

        let indexActual = arrActual.findIndex((x) => x.idActividad == nuevoDato.idActividad);

        if (indexActual != -1) {
            arrActual.splice(indexActual, 1);
            setActividad([...arrActual]);
        }
    };

    const abrirEditarCurso = (row) => {
        row = {
            ...row,
            estatusCurso: {
                idEstatus: row.idActividadEstatus,
                nCursoEstatus: row.nCursoEstatus
            },
            extemporaneo: row.extemporaneo === 'Si' ? '1' : '0',
            interno: row.interno === 'Si' ? '1' : '0',
            puesto: row.npuesto ? { idpuesto: row.idpuesto, npuesto: row.npuesto } : null
        };
        context.formulario.abrir({
            open: true,
            titulo: `Editar la actividad ${row.titulo}`,
            propsForm: { data: row, ctl: ctl },
            formulario: FormActividad,
            aceptar: (dataForm) => {
                serviciosCursos.updateActividad(
                    (res) => {
                        actualizarDataV2(res.actividad);
                        context.formulario.cerrar();
                    },
                    { idActividad: row.idActividad, ...dataForm, idTemario: temaActual.idTemario }
                );
            }
        });
    };
    const abrirNuevoCurso = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: 'Nueva actividad',
            propsForm: { data: null, ctl: ctl },
            formulario: FormActividad,
            aceptar: (dataForm) => {
                serviciosCursos.insertActividad(
                    (res) => {
                        setActividad(res.actividad);
                        context.formulario.cerrar();
                    },
                    { ...dataForm, orden: actividad.length + 1, idTemario: temaActual.idTemario }
                );
            }
        });
    };
    const abrirOrdenar = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: 'Ordenar Actividades',
            propsForm: { data: { dataOrden: actividad }, ctl: ctl },
            formulario: FormOrdenar,
            aceptar: (dataForm) => {
                if (dataForm.dataOrdenada == undefined) return context.mensaje({ open: true, value: 'No se ha hecho ningún orden' });
                serviciosCursos.updateOrdenActividad(
                    (res) => {
                        setActividad(res.actividad);
                        context.formulario.cerrar();
                    },
                    { ...dataForm, idTemario: temaActual.idTemario }
                );
                return true;
            }
        });
    };
    const abrirConfirmarCambio = (actividad) => {
        let texto = `¿Estás seguro de volver a activar la actividad ${actividad.titulo}?`;
        let status = 1;
        if (actividad.estatus === '1') {
            texto = `¿Estás seguro de eliminar la actividad ${actividad.titulo}? No se tomará en cuenta para calificar al colaborador.`;
            status = -1;
        }
        setConfirm({
            open: true,
            text: texto,
            aceptar: () =>
                serviciosCursos.cambiarEstatusActividad(
                    (nuevaData) => {
                        quitaData(actividad);
                        setConfirm({ open: false });
                    },
                    { idActividad: actividad.idActividad, estatus: status, idTemario: temaActual.idTemario }
                )
        });
    };

    const guardarDependencia = (dependenciaSelected, regSelected) => (event) => {
        const dataSend = {
            dataSelected: dependenciaSelected,
            idTemario: temaActual.idTemario,
            idActividad: regSelected.idActividad
        };
        serviciosCursos.guardarDependenciaActividad((res) => {
            setActividad(res.actividad);
            cerrarModalCurso();
        }, dataSend);
    };

    const openFormDependencia = (selected) => {
        setModal({
            open: true,
            titulo: 'Agregar dependencias',
            formulario: FormDependenciaActividad,
            propsModal: {
                data: {
                    selected: selected,
                    allData: actividad.filter((act) => act.idActividad != selected.idActividad),
                    columnKey: 'idActividad'
                },
                headers: headers(null, { estatusCurso: null }, false).actividad,
                guardarDependencia: guardarDependencia,
                cerrar: cerrarModalCurso
            }
        });
    };

    // Config
    const acciones = [
        { label: 'Nueva actividad', icon: <AddBox />, click: abrirNuevoCurso, disabled: !permisos.modificar },
        { label: 'Ordenar actividades', icon: <Reorder />, click: abrirOrdenar, disabled: !permisos.modificar }
    ];
    const accionesFila = [
        {
            label: 'Material de la actividad',
            onClick: (actividadSeleccionado) => onCambioNivel(actividadSeleccionado, actividad),
            enabled: true
        },
        { label: 'Editar', onClick: abrirEditarCurso, enabled: (row) => row.estatus == '1' && permisos.modificar },
        { label: 'Dependencia', onClick: openFormDependencia, enabled: (row) => row.estatus == '1' && permisos.modificar },
        { label: 'Eliminar', onClick: abrirConfirmarCambio, enabled: permisos.modificar }
    ];
    // useEffect(() => {
    //     if (tema && tema != null) {
    //         setTemaActual(tema);
    //         if (!curso || curso == null) {
    //             serviciosCursos.getActividadTema(
    //                 (ct) => {
    //                     setActividad(ct.actividad || []);
    //                 },
    //                 { idTemario: tema.idTemario }
    //             );
    //         }
    //     } else {
    //         setActividad([]);
    //     }
    // }, [tema]);

    useEffect(() => {
        if (curso && curso != null) {
            serviciosCursos.getTemarioTrainers(
                (ct) => {
                    setTemaActual(ct.temario[0]);
                    setActividad(ct.actividades || []);
                },
                { idSesion: curso.idSesion }
            );
        }
    }, [curso]);
    return (
        <>
            <Tabla
                rows={actividad}
                columns={headers(abrirConfirmarCambio, ctl, permisos).actividad}
                accionesTabla={acciones}
                accionesFila={accionesFila}
                columnKey="idActividad"
            />
            <AvisoConfirmacion
                open={confirm.open}
                title="Advertencia"
                text={confirm.text}
                accept={confirm.aceptar}
                cancel={() => setConfirm({ open: false })}
            />
            <ModalGeneral open={modal.open} width="md" titulo={modal.titulo} OnCerrar={cerrarModalCurso}>
                {modal.formulario && <modal.formulario {...modal.propsModal} />}
            </ModalGeneral>
        </>
    );
};

export default Actividad;
