import { AddBox } from '@mui/icons-material';
import React, { useState, useEffect, useContext } from 'react';
import LayautContext from 'Context/ContextLayaut';
import { Tabla, ModalGeneral } from 'ui-component';
import ServiciosCursosTrainers from 'views/catalogo/CursosTrainerMaderas/components/services';
import Services from 'views/Trainer/Components/services';
import { Button } from '@mui/material';
import NuevoCursoTrainer from './NuevoCursoTrainer';
import ColaboradorCard from 'views/Dash/Components/ColaboradorCard';

const FormCursos = ({ colaborador }) => {
    const [cursos, setCursos] = useState([]);
    const [colabCursos, setColabCursos] = useState();
    const servicios = ServiciosCursosTrainers();
    const serviciosTr = Services();
    const [modal, setModal] = useState({ open: false, titulo: '' });
    const cerrarModal = () => setModal({ open: false });
    const services = Services();
    const context = useContext(LayautContext);

    const abrirNuevoCurso = () => () => {
        context.formulario.abrir({
            open: true,
            titulo: 'Agregar curso',
            width: 'sm',
            formulario: NuevoCursoTrainer,
            propsForm: { alumno: colabCursos },
            aceptar: (data) => {
                const datosGrupo = data.grupo;
                if (cursos && cursos.length > 0) {
                    for (let i = 0; i < cursos.length; i++) {
                        let cu = cursos[i];

                        if (cu.idGrupo == datosGrupo.idGrupo) {
                            return setModal({
                                open: true,
                                titulo: 'Error',
                                mensaje: `El colaborador ya tiene el curso ${datosGrupo.titulo} asignado\n`,
                                acciones: (
                                    <Button id="btn-cerrar" variant="outlined" color="info" onClick={cerrarModal}>
                                        cerrar
                                    </Button>
                                )
                            });
                        }
                    }
                }
                services.agregarColaborador(
                    (res) => {
                        setColabCursos({ ...colabCursos, ...res });
                        context.formulario.cerrar();
                    },
                    {
                        alumnos: [{ ...colaborador, isDelete: 0, isMovido: 1, isNuevo: 1 }],
                        form: { grupo: { ...datosGrupo } },
                        withLeader: 0
                    }
                );
            }
        });
    };

    const acciones = [{ label: 'Nuevo Curso', icon: <AddBox />, click: abrirNuevoCurso, disabled: false }];

    const confirmaBaja = (row) => {
        setModal({
            open: true,
            titulo: 'Retirar curso',
            mensaje: 'El colaborador ya no tendrá asignado este curso. ¿Desea continuar?',
            acciones: (
                <Button
                    id="btn-aceptar"
                    variant="outlined"
                    color="info"
                    onClick={() => {
                        services.agregarColaborador(
                            (res) => {
                                setColabCursos({ ...colabCursos });
                                cerrarModal();
                            },
                            {
                                alumnos: [{ ...colaborador, isDelete: 1, isMovido: 1, isNuevo: 0 }],
                                form: { grupo: { ...row } },
                                withLeader: 0
                            }
                        );
                    }}
                >
                    aceptar
                </Button>
            )
        });
    };

    const accionesFila = [{ label: 'Baja', onClick: confirmaBaja, enabled: true }];

    const columnas = [
        { label: 'Título', name: 'titulo', filtrar: true },
        { label: 'Activo', name: 'textoEstatus', filtrar: true }
    ];

    const buscaCursos = (colaborador) => {
        servicios.buscaCursosTrainer(
            (ct) => {
                setCursos(ct.cursosTrainer);
            },
            { idColaborador: colaborador.idColaborador }
        );
    };

    useEffect(() => {
        if (colabCursos && colabCursos.idColaborador && colabCursos.idColaborador != null) {
            buscaCursos(colabCursos);
        }
    }, [colabCursos]);

    useEffect(() => {
        if (colaborador) {
            setColabCursos({ ...colaborador });
            //buscaCursos(colaborador);
        }
    }, [colaborador]);
    return (
        <>
            <Tabla rows={cursos} columns={columnas} accionesTabla={acciones} accionesFila={accionesFila} columnKey="idCurso" />
            <ModalGeneral
                open={modal.open}
                width={'xs'}
                titulo={modal.titulo}
                OnCerrar={cerrarModal}
                ContenidoMensaje={modal.mensaje}
                acciones={modal.acciones}
            >
                {modal.contenido && <modal.contenido {...modal.props} />}
            </ModalGeneral>
        </>
    );
};

export default FormCursos;
