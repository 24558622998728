import React, { useEffect, useState } from 'react';

import { ModalGeneral, Tabla } from 'ui-component';
import { List, ListItem, ListItemIcon, ListItemText, IconButton, Typography, Stack, Box } from '@mui/material';
import { Person } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Scrollbars } from 'react-custom-scrollbars';

const columns = () => [
    { label: 'Posición', name: 'posicion', filtrar: false },
    { label: 'Colaborador', name: 'nombre', filtrar: true },
    { label: 'Promedio', name: 'promedio', filtrar: false, type: (row) => parseFloat(row.promedio, 10).toFixed(1) }
];

const Ranking = ({ colaboradores }) => {
    const theme = useTheme();
    const [detalle, setDetalle] = useState({ open: false, colaborador: null });
    // const abrirDetalle = (row) => setDetalle({ open: true, colaborador: row });
    // const cerrarDetalle = (event) => setDetalle({ ...detalle, open: false });
    return (
        <>
            <Scrollbars style={{ height: 340 }}>
                <List component="nav" aria-label="main mailbox folders">
                    {colaboradores?.map((col) => {
                        const { nombre, posicion } = col;
                        const tituloCurso = col?.titulo;
                        let promedio = parseFloat(col.promedio, 10).toFixed(1);
                        promedio = isNaN(promedio) ? '0.0' : promedio;
                        const cardColor = col?.isYo ? theme.palette.secondary.light : null;
                        return (
                            <ListItem sx={{ backgroundColor: cardColor }}>
                                <Stack direction="row" display="flex" alignItems="center">
                                    <ListItemText sx={{ pr: 2 }} primary={posicion} />
                                    <ListItemIcon>
                                        <Person color="blue" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={nombre}
                                        secondary={
                                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="cardSubtitle">{`Curso: ${tituloCurso || '---'}`}</Typography>
                                                <Typography variant="cardSubtitle">{`Promedio: ${promedio}`}</Typography>
                                            </Box>
                                        }
                                    />
                                </Stack>
                            </ListItem>
                        );
                    })}
                </List>
            </Scrollbars>

            {/* <Tabla columns={columns()} filterGeneralDisabled rows={colaboradores} columnKey="idColaborador" /> */}
            {/* <ModalGeneral
                open={detalle.open}
                titulo="Información del colaborador"
                acciones={<Button onClick={cerrarDetalle}>Cerrar</Button>}
                width="sm"
            >
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="caption">Nombre</Typography>
                            <br />
                            <Typography variant="button">{detalle.colaborador?.nombreUsuario}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption">Correo</Typography>
                            <br />
                            <Typography variant="button">{detalle.colaborador?.correo}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption">Telefono</Typography>
                            <br />
                            <Typography variant="button">{detalle.colaborador?.telefono}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption">Activo desde</Typography>
                            <br />
                            <Typography variant="button">{detalle.colaborador?.fcrea || '---'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption">Primer acceso</Typography>
                            <br />
                            <Typography variant="button">{detalle.colaborador?.primerConeccion}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption">Último acceso</Typography>
                            <br />
                            <Typography variant="button">{detalle.colaborador?.ultimaConeccion}</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </ModalGeneral> */}
        </>
    );
};

export default Ranking;
