import React, { useState, useEffect, useRef, useContext } from 'react';
import LayautContext from 'Context/ContextLayaut';

import { makeStyles } from '@mui/styles';
import { Container, Box, colors, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { AddBox, PublishedWithChanges, Block } from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';
import PropTypes from 'prop-types';

import { Tabla } from 'ui-component';
import MainCard from 'ui-component/cards/MainCard';
import AgregarColaborador from './Components/AgregarColaborador';
import CursoSesion from './Components/CursoSesion';
import Confirm from './Components/Confirm';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Services from './Components/services';
import headers from './Components/headers';
import { ConsoleView } from 'react-device-detect';
import { useLogin } from 'Context/ContextLogin';

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center'
    },
    cancelar: {
        color: colors.red[500]
    },
    aceptar: {
        color: colors.green[500]
    },
    texto: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 13
    },
    textoPequeno: {
        color: colors.indigo[900],
        textAlign: 'center',
        fontSize: 9
    },
    textoTabla: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 9
    },
    textoTablaTH: {
        color: colors.indigo[900],
        textAlign: 'left',
        fontSize: 11
    },
    cantidadTabla: {
        color: colors.indigo[900],
        textAlign: 'right',
        fontSize: 9
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    },
    btnAceptar: {
        color: colors.green[500],
        '&:hover': {
            color: theme.palette.success.dark
        }
    },
    btnBorrar: {
        color: colors.red[500],
        '&:hover': {
            color: theme.palette.warning.light
        }
    }
}));

const Trainer = ({ permisos, ...props }) => {
    const classes = useStyles();
    const services = Services();
    const { usuario } = useLogin();
    const [confirm, setConfirm] = useState({ open: false, title: '', text: '', cancel: () => {}, accept: () => {} });
    const [agregarColaborador, setAgregarColaborador] = useState({
        open: false,
        title: '',
        data: { row: [], personal: [] },
        cancel: () => {}
    });
    const [cursoSesion, setCursoSesion] = useState({
        open: false,
        title: '',
        data: { row: [], personal: [] },
        cancel: () => {}
    });
    const [value, setValue] = useState('1');
    const [personal, setPersonal] = useState({ personal: [], allData: [] });
    const [personalOOAM, setPersonalOOAM] = useState({ personal: [], allData: [] });

    const [administrativo, setAdministrativo] = useState([]);
    const [operativo, setOperativo] = useState([]);
    // Services
    const getInfoInicial = () => {
        services.getInfoInicial(
            (res) => {
                setPersonal({ personal: res.personal });
            },
            { tipo: '1' }
        );
    };

    const getInfoInicialOOAM = () => {
        services.getInfoInicial(
            (res) => {
                setPersonalOOAM({ personal: res.personal });
            },
            { tipo: '2' }
        );
    };

    // Functions
    const cambioTab = (event, value) => {
        setValue(value);
        if (value === '2' && personalOOAM.personal.length == 0) getInfoInicialOOAM();
    };

    const cargarFunciones = () => {
        getInfoInicial();
    };

    const closeConfirm = () => {
        setConfirm({ open: false, title: '', text: '', cancel: () => {}, accept: () => {} });
    };

    const closeAgregarColaborador = () => {
        setAgregarColaborador({ open: false, title: '', data: { row: [], personal: [] }, cancel: () => {} });
    };

    const closeCursoSesion = () => {
        setCursoSesion({ open: false, title: '', data: { row: [], personal: [] }, cancel: () => {} });
    };

    const ascenderATrainer = (row) => {
        setConfirm({
            open: true,
            title: `Ascender a trainer`,
            text: `${row.nombre_persona} se convertirá en trainer.`,
            cancel: closeConfirm,
            accept: () => {
                services.ascenderATrainer((res) => {
                    closeConfirm();
                    getInfoInicial();
                }, row);
            }
        });
    };

    const degradarTrainer = (row) => {
        setConfirm({
            open: true,
            title: `Desactivar trainer`,
            text: `${row.nombre_persona} dejará de ser trainer.`,
            cancel: closeConfirm,
            accept: () => {
                services.degradarTrainer((res) => {
                    closeConfirm();
                    getInfoInicial();
                }, row);
            }
        });
    };

    const cursoSesAvance = (row) => {
        setCursoSesion({
            open: true,
            title: 'Curso y Sesiones',
            data: { personal: personalOOAM.personal, tipo: value, withLeader: 0, persona: row },
            cancel: closeCursoSesion
        });
    };

    const asignarColaborador = (row) => {
        services.getSinAsignar((res) => {
            setAgregarColaborador({
                open: true,
                title: 'Agregar colaborador',
                data: { row: row, personal: res.personal, withLeader: 1 },
                cancel: closeAgregarColaborador
            });
        });
    };

    const bloquearColaborador = (row) => {
        const dataSend = { idContrato: row.idcontrato, idPersona: row.idpersona, isBloqueado: !(row.isBloqueado == 1), tipo: value };
        services.bloquearColaborador((res) => {
            let newPersonal = [];
            if (value == '1') newPersonal = personal.personal;
            else newPersonal = personalOOAM.personal;
            const index = newPersonal.findIndex((per) => per.idcontrato == res.personal.idcontrato);
            if (index != -1) newPersonal[index] = res.personal;
            setPersonal({ ...personal, personal: newPersonal });
        }, dataSend);
    };

    const clickBloq = (estatus, data) => {
        const dataSend = { estatus: estatus, data: data };

        services.bloquearColaboradorMasivo((res) => {
            setPersonal({ personal: res.personal });
        }, dataSend);
    };

    const asigGrup = () => {
        setAgregarColaborador({
            open: true,
            title: 'Agregar colaborador',
            data: { personal: value === '1' ? personal.personal : personalOOAM.personal, tipo: value, withLeader: 0 },
            cancel: closeAgregarColaborador
        });
    };
    useEffect(() => {
        cargarFunciones();
    }, []);
    const acciones = [
        {
            label: 'Asignar Grupo',
            icon: <GroupsIcon />,
            click: (data) => (even) => {
                asigGrup(1, data);
            },
            dataSelected: false,
            color: classes.btnBorrar
        },
        {
            label: 'Bloquear',
            icon: <Block />,
            click: (data) => (even) => {
                clickBloq(1, data);
            },
            dataSelected: true,
            color: classes.btnBorrar
        },
        {
            label: 'Desbloquear',
            icon: <PublishedWithChanges />,
            click: (data) => (even) => {
                clickBloq(0, data);
            },
            dataSelected: true,
            color: classes.btnAceptar
        }
    ];
    const accionesFila = [
        { label: 'Ascender a Trainer', onClick: ascenderATrainer, enabled: (row) => !(row.isTrainer == 1) },
        { label: 'Degradar Trainer', onClick: degradarTrainer, enabled: (row) => row.isTrainer == 1 },
        { label: 'Asignar Colaborador', onClick: asignarColaborador, enabled: (row) => row.isTrainer == 1 },
        { label: 'Enviar Acumulado', onClick: (row) => {}, enabled: true },
        { label: 'Bloquear', onClick: (row) => bloquearColaborador(row), enabled: (row) => row.isBloqueado == 0 },
        { label: 'Desbloquear', onClick: (row) => bloquearColaborador(row), enabled: (row) => row.isBloqueado == 1 },
        { label: 'Asignar Grupo', onClick: ascenderATrainer, enabled: (row) => !(row.isTrainer == 1) }
    ];

    const accionesFilaOOAM = [
        { label: 'Bloquear', onClick: (row) => bloquearColaborador(row), enabled: (row) => row.isBloqueado == 0 },
        { label: 'Desbloquear', onClick: (row) => bloquearColaborador(row), enabled: (row) => row.isBloqueado == 1 },
        { label: 'Activar Sesiones', onClick: cursoSesAvance, enabled: (row) => !(row.isTrainer == 1) }
    ];

    return (
        <MainCard title="Lista asesores">
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={cambioTab} aria-label="lab API tabs example">
                        <Tab label="Ventas" value="1" />
                        <Tab label="OOAM" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Tabla
                        id="tablaVentas"
                        rows={personal.personal}
                        columns={headers(() => {}).personal}
                        accionesFila={accionesFila}
                        accionesTabla={acciones}
                        columnKey="idpersona"
                        isSeleccionable
                    />
                </TabPanel>
                <TabPanel value="2">
                    <Tabla
                        id="tablaOOAM"
                        rows={personalOOAM.personal}
                        columns={headers(() => {}).personal}
                        accionesFila={accionesFilaOOAM}
                        columnKey="idpersona"
                    />
                </TabPanel>
                <Confirm open={confirm.open} title={confirm.title} text={confirm.text} cancel={confirm.cancel} accept={confirm.accept} />
                <AgregarColaborador
                    open={agregarColaborador.open}
                    title={agregarColaborador.title}
                    data={agregarColaborador.data}
                    cancel={agregarColaborador.cancel}
                    updatesetPersonal={value == 1 ? getInfoInicial : getInfoInicialOOAM}
                    infoUsuario={props.usuario}
                    opcion={value}
                    esAsesores={true}
                />
                <CursoSesion
                    open={cursoSesion.open}
                    title={cursoSesion.title}
                    data={cursoSesion.data}
                    cancel={cursoSesion.cancel}
                    updatesetPersonal={getInfoInicial}
                    setTabla={setPersonalOOAM}
                    tabla={personalOOAM?.personal}
                    infoUsuario={props.usuario}
                />
            </TabContext>
        </MainCard>
    );
};

export default Trainer;
