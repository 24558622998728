import React, { useState, useEffect, useContext, useMemo } from 'react';
import { AddBox } from '@mui/icons-material';
import { ModalGeneral, Tabla } from 'ui-component';
import headers from './components/headers';
import MainCard from 'ui-component/cards/MainCard';
import ServiciosCalificacion from './components/ServiciosCalificacion';
import { Button, Grid, Tab, Tabs, ToggleButton, ToggleButtonGroup } from '@mui/material';
import CursosCalificacionTree from './components/CursosTree';
import { makeStyles } from '@mui/styles';
import { useLogin } from 'Context/ContextLogin';

const Calificaciones = ({ permisos }) => {
    const useStyles = makeStyles((theme) => ({
        btnAceptar: {
            color: theme.palette.btn.green,
            '&:hover': {
                backgroundColor: theme.palette.btn.green,
                color: theme.palette.btn.white
            }
        },
        btnCancelar: {
            color: theme.palette.btn.red,
            '&:hover': {
                backgroundColor: theme.palette.btn.red,
                color: theme.palette.btn.white
            }
        }
    }));
    const {
        usuario: { areas }
    } = useLogin();
    const serviciosCalificacion = ServiciosCalificacion();
    // States
    const [grupo, setGrupo] = useState({ opcion: areas[0]?.idarea || '', data: [] });
    const classes = useStyles();
    const [modal, setModal] = useState({ open: false, props: null, componente: null });
    const verCalificacionCurso = (row) => {
        serviciosCalificacion.getTrainerCalificacion(
            (data) => {
                setModal({
                    open: true,
                    titulo: `Calificación por curso para ${row.nombre_persona}`,
                    props: { colaborador: { ...row, ...data, permisos } },
                    componente: CursosCalificacionTree
                });
            },
            { idcolaborador: row.idColaborador }
        );
    };

    const cerrarModal = () => {
        setModal({
            ...modal,
            open: false
        });
    };
    const clickCambioTipoPuesto = (event, value) => {
        // setGrupo({ ...grupo, opcion: value });
        serviciosCalificacion.getTrainerCursosPuesto(
            (data) => {
                setGrupo({ opcion: value, data: data.colaboradores });
            },
            { opcion: value }
        );
    };
    // Config
    const acciones = [];
    const accionesFila = [
        {
            label: 'Calificación por curso',
            onClick: verCalificacionCurso,
            enabled: true
        }
    ];

    useEffect(() => {
        if (areas?.length) {
            setGrupo({ ...grupo, opcion: areas[0].idarea });
            clickCambioTipoPuesto({}, areas[0].idarea);
        }
    }, [areas]);
    return (
        <MainCard
            title={
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>Calificaciones</Grid>
                    {areas.length > 1 && (
                        <Grid item>
                            <Tabs
                                value={grupo.opcion != '' ? grupo.opcion : areas[0].idarea}
                                onChange={clickCambioTipoPuesto}
                                aria-label="basic tabs example"
                            >
                                {areas.map((area) => (
                                    <Tab label={area.narea} value={area.idarea} />
                                ))}
                            </Tabs>
                        </Grid>
                    )}
                </Grid>
            }
        >
            <Tabla
                rows={grupo.data}
                columns={headers().colaboradores}
                accionesTabla={acciones}
                accionesFila={accionesFila}
                columnKey="idcolaborador"
            />
            <ModalGeneral
                open={modal.open}
                titulo={modal.titulo}
                width="lg"
                acciones={
                    <Button onClick={cerrarModal} className={classes.btnCancelar}>
                        Cerrar
                    </Button>
                }
            >
                {modal.componente && <modal.componente {...modal.props} />}
            </ModalGeneral>
        </MainCard>
    );
};

export default Calificaciones;
