import React, { useEffect, useState } from 'react';
// material-ui
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Grade } from '@mui/icons-material';
import { ReactComponent as IconStar } from 'assets/images/icons/c-estrella.svg';
import { Box, LinearProgress, Card, CardMedia, CardActions, CardContent, Typography, Stack, Tooltip, CardHeader } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { BigPlayButton, ControlBar, VolumeMenuButton, Player } from 'video-react';

// project imports
import Services from 'utils/serviciosVentas';
import { height } from '@mui/system';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const style = {
    card: {
        boxShadow: '8px 5px 5px #676767',
        backgroundColor: '#efefef',
        borderRadius: 0,
        width: 310,
        height: 500,
        margin: '0 0 20px 20px',
        cursor: 'pointer'
    },
    contentText: { textAlign: 'left' }
};

const Inicio = (props) => {
    const { permiso, usuario, curso, esCompleto } = props;
    const services = Services();
    const theme = useTheme();
    const srcPortada = curso?.portadaCursoMostrar
        ? curso.portadaCursoMostrar
        : 'https://cdn.pixabay.com/photo/2012/12/14/04/41/e-mail-69911_960_720.jpg';
    const arrImagen = ['jpg', 'jpeg', 'png', 'gif'];
    const arrVideo = ['mp4', 'wmv', 'avi', 'mkv'];
    const [tipoPortada, setTipoPortada] = useState('img');
    // Components
    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 10,
            borderRadius: 0
        },
        colorPrimary: {
            backgroundColor: theme.palette.dark[100]
        },
        bar: {
            borderRadius: 0,
            backgroundColor: theme.palette.success.dark
        }
    }))(LinearProgress);
    const IconTooltip = ({ title, children }) => (
        <Tooltip title={title} disableInteractive>
            {children}
        </Tooltip>
    );

    useEffect(() => {
        if (curso && curso.portadaCurso) {
            const arr = curso.portadaCurso.split('.');
            const extension = arr[arr.length - 1];

            if (arrImagen.includes(extension)) {
                setTipoPortada('img');
            } else if (arrVideo.includes(extension)) {
                setTipoPortada('video');
            }
        }
    }, [curso]);

    return (
        <Card sx={style.card} raised>
            <CardHeader
                title={
                    <Typography variant="h2" sx={{ color: '#c0a974', fontWeight: 'bold' }}>
                        {curso.titulo}
                    </Typography>
                }
                sx={{ textAlign: 'center', color: '#c0a974', height: 103 }}
                subheaderTypographyProps={{ color: '#c0a974', fontSize: '20px' }}
                onClick={() => {
                    //if (!esCompleto) props.selectCurso(curso.idCurso);
                    props.selectCurso(curso.idCurso);
                }}
            />

            {tipoPortada == 'img' ? (
                <CardMedia
                    onClick={() => {
                        // if (!esCompleto) props.selectCurso(curso.idCurso);
                        props.selectCurso(curso.idCurso);
                    }}
                    component="img"
                    // sx={{ objectFit: 'contain' }}
                    height="200"
                    image={srcPortada}
                    alt="portada"
                />
            ) : (
                <CardContent sx={{ display: 'flex', alignItems: 'center', p: 0 }}>
                    <Player playsInline src={srcPortada} height={200} width={310} fluid={false} muted>
                        <BigPlayButton position="center" />
                        <ControlBar autoHide className="my-class">
                            <VolumeMenuButton vertical />
                        </ControlBar>
                    </Player>
                </CardContent>
                // <CardMedia component="video" height="200" src={srcPortada} alt="portada" autoPlay muted />
            )}

            <CardActions
                sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                onClick={() => {
                    // if (!esCompleto) props.selectCurso(curso.idCurso);
                    props.selectCurso(curso.idCurso);
                }}
            >
                <Typography variant="body1" align="center">
                    {curso.subtitulo}
                </Typography>
                <Typography variant="body1" align="center">
                    <strong>{`Calificación ${curso.calificacion}`}</strong>
                </Typography>
                <Typography variant="body1" align="center">
                    <strong>{`Avance ${curso.porcentajeCompletado}%`}</strong>
                </Typography>
            </CardActions>

            {/* <Card sx={style.card} onClick={() => props.selectCurso(curso.idCurso)} raised>
            <CardMedia component="img" height="300" image={srcPortada} alt="portada" />
            <CardContent sx={{ p: 1, textAlign: 'left' }}>
                <Stack spacing={0.5} alignItems="center">
                    <Typography variant="cardTitle">{curso.titulo}</Typography>
                    <Typography variant="cardSubtitle">{curso.subtitulo}</Typography>
                </Stack>
            </CardContent>
            <BorderLinearProgress sx={{ width: '100%' }} variant="determinate" value={curso.porcentajeCompletado} />
            <CardActions sx={{ p: 1, textAlign: 'left' }}>
                <Stack sx={{ width: '100%' }}>
                  
                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <IconTooltip title="Progreso">
                            <Typography variant="cardSubtitle">{`${Math.round(curso.porcentajeCompletado)}%`}</Typography>
                        </IconTooltip>
                        <IconStar style={{ ...theme.typography.iconSm, margin: '0px 5px 0px 5px' }} />
                        <IconTooltip title="Promedio">
                            <Typography variant="cardSubtitle">{`${curso.calificacion.toFixed(2)}%`}</Typography>
                        </IconTooltip>
                    </Box>
                </Stack>
            </CardActions> 

            
        </Card>*/}
        </Card>
    );
};

export default Inicio;
