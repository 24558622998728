import React, { useState, useEffect } from 'react';
import { School, Group } from '@mui/icons-material';
import { Card, CardContent, Grid, Typography, Tooltip, IconButton, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BigPlayButton, Player } from 'video-react';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const useStyles = makeStyles((theme) => ({
    curso: {
        backgroundColor: theme.palette.secondary[800]
    },
    grupo: {
        borderRadius: '8px',
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary[800]
    }
}));

const Video = ({ permiso, usuario, data, onClickFinalizar, ...props }) => {
    const classes = useStyles();
    const video = data?.video;

    // Functions
    const finalizar = () => {
        onClickFinalizar({
            tipo: data.tipo,
            idDetalleActividad: data.idDetalleActividad,
            idColaborador: data.idColaborador,
            idActividad: data.idActividad,
            idTemario: data.idTemario,
            idSesion: data.idSesion,
            idCurso: data.idCurso
        });
    };

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Player playsInline poster="/assets/poster.png" src={video.linkVideo}>
                    <BigPlayButton position="center" />
                </Player>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="end">
                <Button variant="contained" sx={{ backgroundColor: '#003764', color: '#FFFFFF', borderRadius: '0px' }} onClick={finalizar}>
                    Terminado
                </Button>
            </Grid>
        </Grid>
    );
};

export default Video;
