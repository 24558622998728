import React, { useState, useEffect, useRef, useContext } from 'react';

import { Button, Grid, Tab, Tabs, Typography, Box, FormControlLabel, Checkbox } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ModalGeneral, Tabla } from 'ui-component';
import MainCard from 'ui-component/cards/MainCard';
import AutocompleteForm from 'ui-component/AutocompleteForm';
import { makeStyles } from '@mui/styles';
import Services from './Components/services';
import headers from './Components/headers';
import { useLogin } from 'Context/ContextLogin';
import ServiciosCalificacion from 'views/Calificaciones/components/ServiciosCalificacion';
import CursosCalificacionTree from 'views/Calificaciones/components/CursosTree';
import ReporteGeneral from './ReporteGeneral';

const DetalleCalificacion = ({ row, arreglo }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Typography variant="h5" sx={{ m: '5px' }}>
                    Total de evaluaciones: {row.numExamenes || '-'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="h5" sx={{ m: '5px' }}>
                    Evaluaciones finalizadas: {row.numRespondidos || '-'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="h5" sx={{ m: '5px' }}>
                    Inicio de revisión de material: {row.inicio || '-'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="h5" sx={{ m: '5px' }}>
                    Última revisión del material: {row.fin || '-'}
                </Typography>
            </Grid>
        </Grid>
    );
};

const Trainer = ({ permisos, usuario, ...props }) => {
    const useStyles = makeStyles((theme) => ({
        btnAceptar: {
            color: theme.palette.btn.green,
            '&:hover': {
                backgroundColor: theme.palette.btn.green,
                color: theme.palette.btn.white
            }
        },
        btnCancelar: {
            color: theme.palette.btn.red,
            '&:hover': {
                backgroundColor: theme.palette.btn.red,
                color: theme.palette.btn.white
            }
        }
    }));
    const classes = useStyles();

    const services = Services();
    const serviciosCalificacion = ServiciosCalificacion();
    const [modal, setModal] = useState({ open: false, props: null, componente: null });
    const {
        usuario: { areas }
    } = useLogin();
    const { control, getValues, setValue } = useForm();

    // States
    const [seguimiento, setSeguimiento] = useState({ allData: [], filtrado: [], idReporte: undefined, idSesion: undefined });
    const [datosObtenidos, setDatosObtenidos] = useState([]);
    const [activo, setActivo] = useState(false);
    const [sesiones, setSesiones] = useState([]);
    const [activoGrupo, setActivoGrupo] = useState(false);
    const [reporte, setReporte] = useState(0);
    const [grupos, setGrupos] = useState([]);
    const [option, setOption] = useState(areas[0]?.idarea || null);

    const cerrarModal = () => {
        setModal({
            ...modal,
            open: false
        });
    };

    const Reporte = [
        { reporte: '', idReporte: 99, areas: ['1', '3', '4'] },
        { reporte: 'REPORTE DE CALIFICACIONES', idReporte: 1, areas: ['1', '3', '4'] },
        { reporte: 'REPORTE GLOBAL PROGRESO', idReporte: 2, areas: ['1', '3', '4'] },
        { reporte: 'REPORTE REGISTRADOS', idReporte: 3, areas: ['1', '3', '4'] },
        { reporte: 'REPORTE DE GRUPOS', idReporte: 4, areas: ['3'] },
        { reporte: 'REPORTE DE CURSOS', idReporte: 4, areas: ['1'] },
        { reporte: 'REPORTE DE FINALIZACIÓN', idReporte: 5, areas: ['1', '3', '4'] },
        { reporte: 'REPORTE ADMINISTRATIVO', idReporte: 6, areas: ['1'] },
        { reporte: 'REPORTE GENERAL', idReporte: 7, areas: ['1', '3', '4'] }
    ];

    const verCalificacionCurso = (row) => {
        const curso = getValues('itemCurso')?.idCurso;
        serviciosCalificacion.getTrainerCalificacion(
            (data) => {
                setModal({
                    open: true,
                    titulo: `Calificación por curso para ${row.nombre_persona}`,
                    props: { colaborador: { ...row, ...data, permisos }, numCurso: curso },
                    componente: CursosCalificacionTree
                });
            },
            { idcolaborador: row.idColaborador }
        );
    };

    const accionesReporte = [
        {
            label: 'Detalle de calificaciones',
            onClick: verCalificacionCurso,
            enabled: true
        }
    ];

    // services
    const getReportesModulo = (idReporte, sesion, grupo) => {
        const idSesion = sesion;
        const idGrupo = grupo;
        if (idReporte == undefined) return false;
        services.getReporteModulo(
            (res) => {
                // crear atributo de objeto por cada temario
                if (res[0]?.temarios) {
                    res.forEach((info) => {
                        let keys = info?.temarios ? Object.keys(info?.temarios || {}) : [];
                        keys.forEach((key) => {
                            info[key] = info?.temarios[key];
                        });
                    });
                }
                setSeguimiento({
                    allData: [...seguimiento.allData, ...res],
                    filtrado: res,
                    idReporte: idReporte,
                    idSesion: idSesion,
                    idGrupo: idGrupo
                });
                setDatosObtenidos([...datosObtenidos, idSesion, idGrupo]);
            },
            { idReporte, idSesion, idGrupo, option }
        );
        return true;
    };

    // Functions
    const ejecutarFiltro = () => {
        const idSesion = getValues('sesion')?.idSesion;
        const idGrupo = getValues('grupo')?.idGrupo;
        const idReporte = reporte.idReporte;
        getReportesModulo(idReporte, idSesion, idGrupo);
    };

    const handleChange = (object) => {
        if (object == null) {
            return true;
        }
        setReporte(object);

        if (![2, 3, 5, 6, 7].includes(object.idReporte)) {
            setActivo(true);
            if (object.idReporte == 4) {
                setActivoGrupo(true);
            } else {
                setActivoGrupo(false);
            }
            return true;
        }

        setActivo(false);
        setActivoGrupo(false);
        return true;
    };

    const clickCambioTipo = (event, value) => {
        setOption(value);
        setSeguimiento({ allData: [], filtrado: [], idReporte: undefined, idSesion: undefined });
    };

    // Config
    const acciones = [];
    const accionesFila = [];
    useEffect(() => {
        if (areas?.length) setOption(areas[0].idarea);
    }, [areas]);
    useEffect(() => {
        services.grupoInt((ct) => {
            setGrupos(ct.grupo || []);
            setSesiones(ct.sesiones || []);
        });
    }, []);

    return (
        <MainCard title="Seguimiento">
            <Grid container spacing={2}>
                <Grid item md={12}>
                    {areas.length > 1 && (
                        <Grid item>
                            <Tabs value={option ? option : areas[0].idarea} onChange={clickCambioTipo} aria-label="basic tabs example">
                                {areas.map((area) => (
                                    <Tab label={area.narea} value={area.idarea} />
                                ))}
                            </Tabs>
                        </Grid>
                    )}
                </Grid>

                {option == 1 ? (
                    <Grid item xs={12}>
                        <ReporteGeneral permisos={permisos} />
                    </Grid>
                ) : (
                    <Grid item md={12} container spacing={2}>
                        <Grid item md={3}>
                            <AutocompleteForm
                                id="Reportes"
                                key={`report${option}`}
                                label="Reporte"
                                control={control}
                                name="Reporte"
                                rules={{ required: { value: true, message: 'Campo requerido' } }}
                                options={Reporte.filter((repo) => repo.areas.includes(option))}
                                optionSelected={{ label: 'reporte', id: 'idReporte' }}
                                onChange={handleChange}
                            />
                        </Grid>

                        {activoGrupo == true ? (
                            <Grid item md={3}>
                                <AutocompleteForm
                                    key={`report${option}`}
                                    label={option == '1' ? 'Curso' : 'Grupo'}
                                    control={control}
                                    name="grupo"
                                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                                    options={grupos.filter((grupo) => grupo.tipo_grupo == (option == '1' ? 'default' : 'grupo'))}
                                    optionSelected={{ label: 'nombre', id: 'idGrupo' }}
                                    onChange={(grupo) => setValue('grupo', grupo)}
                                />
                            </Grid>
                        ) : null}

                        {activo == true ? (
                            <Grid item md={3}>
                                <AutocompleteForm
                                    key={`report${option}`}
                                    label="Sesión"
                                    control={control}
                                    name="sesion"
                                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                                    options={sesiones}
                                    optionSelected={{ label: 'titulo', id: 'idSesion' }}
                                />
                            </Grid>
                        ) : null}

                        <Grid item md={3}>
                            <Button variant="contained" color="primary" onClick={ejecutarFiltro}>
                                Buscar
                            </Button>
                        </Grid>
                        <Tabla
                            key={`report${option}`}
                            rows={seguimiento.filtrado}
                            columns={headers.seguimiento(seguimiento.idReporte, seguimiento.idSesion, seguimiento.filtrado, option)}
                            accionesFila={accionesFila}
                            columnKey="idpersona"
                        />
                    </Grid>
                )}
            </Grid>

            <ModalGeneral
                open={modal.open}
                titulo={modal.titulo}
                width="lg"
                acciones={
                    <Button onClick={cerrarModal} className={classes.btnCancelar}>
                        Cerrar
                    </Button>
                }
            >
                {modal.componente && <modal.componente {...modal.props} />}
            </ModalGeneral>
        </MainCard>
    );
};

export default Trainer;
