import { Expand, ExpandLess } from '@mui/icons-material';
import { Button, Divider, Grid, TextField, Typography, Avatar } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import SubCardCollapase from 'ui-component/cards/SubCardCollapase';
import ServiciosPerfil from './Components/servicesPerfil';
import { ReactComponent as IconUser } from 'assets/images/icons/avatar-sin-foto.svg';
import { useTheme } from '@mui/material/styles';
import LoginContext from 'Context/ContextLogin';
import portadaGenerica from 'assets/images/PortadaGenerica.png';
import { FormInputImage } from 'ui-component';

const CompEditarFoto = ({ urlActual }) => {
    const context = useContext(LoginContext);
    const servicio = ServiciosPerfil();
    const theme = useTheme();
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        control
    } = useForm();
    const [esEditar, setEsEditar] = useState(false);

    const editarFoto = (dato) => {
        let usuarioActual = context?.login?.usuario;
        let loginActual = context?.login;

        if (dato && dato.imgPerfil.file) {
            const formData = new FormData();
            // si existe portadaCurso file tomar ese valor o si no, si existe portadaCurso objectURL enviar "1", caso contrario enviar "0"
            formData.append('imgPerfil', dato.imgPerfil?.file);
            formData.append('idpersona', usuarioActual.idpersona);
            formData.append('idColaborador', usuarioActual.idColaborador);
            servicio.updateFotoAlumno(
                (res) => {
                    usuarioActual.foto = res;
                    loginActual.usuario = usuarioActual;
                    context.cambioLogin({ ...loginActual });
                    setEsEditar(!esEditar);
                },
                formData,
                {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
            );
        }
    };
    return (
        <>
            {esEditar ? (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <form onSubmit={handleSubmit(editarFoto)}>
                        <FormInputImage
                            id="frm-inp-img"
                            control={control}
                            defaultValue={{
                                objeto: urlActual ? urlActual : portadaGenerica
                            }}
                            name="imgPerfil"
                            alt="imgp"
                        />
                        <Button variant="contained" sx={{ m: 1 }} onClick={() => setEsEditar(!esEditar)}>
                            cancelar
                        </Button>
                        <Button variant="contained" sx={{ m: 1 }} color="success" type="submit">
                            guardar
                        </Button>
                    </form>
                </div>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Avatar
                        sx={{
                            ...theme.typography.mediumAvatar,
                            // margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                            width: 200,
                            height: 200
                        }}
                        aria-haspopup="true"
                        color="inherit"
                    >
                        {urlActual ? (
                            // <img alt="fotoPerfil" src={context?.login?.usuario?.foto} width="100%" height="100%" />
                            <img alt="fotoPerfil" src={urlActual} width="100%" height="100%" />
                        ) : (
                            <IconUser />
                        )}
                    </Avatar>
                    <Button variant="contained" sx={{ m: 1 }} onClick={() => setEsEditar(!esEditar)}>
                        cambiar foto
                    </Button>
                </div>
            )}
        </>
    );
};

const PerfilUsuario = ({ usuario, permisos }) => {
    const servicio = ServiciosPerfil();
    const context = useContext(LoginContext);
    const theme = useTheme();
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        control
    } = useForm();
    const [confirmacion, setConfirmacion] = useState({ open: false, data: null });
    const [usuarioDetalle, setUsuarioDetalle] = useState({});
    const cambiarContrasena = (data) => {
        setConfirmacion({ open: true, data: data });
    };
    const cerrarConfimacion = () => setConfirmacion({ open: false, data: null });
    const aceptarConfirmacion = (event) => {
        servicio.cambiarMiContrasena((event) => {
            cerrarConfimacion();
        }, confirmacion.data);
    };
    useEffect(() => {
        if (usuario) {
            let usuarioBuscar = null;

            if (usuario.idpersona && usuario.idColaborador) {
                usuarioBuscar = {
                    idpersona: usuario.idpersona,
                    idColaborador: usuario.idColaborador
                };
            } else {
                usuarioBuscar = {
                    idrol: usuario.idrol,
                    idusuario: usuario.idusuario
                };
            }

            servicio.getDatosPerfil((data) => {
                setUsuarioDetalle(data);
            }, usuarioBuscar);
        }
    }, [usuario]);

    return (
        <>
            <MainCard title={<Typography variant="title">mi perfil</Typography>}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        {permisos.cambiarContrasena ? (
                            // si es un usuario adminstrativo no muestra foto de perfil y ni tiene de opcion de editar
                            <Avatar
                                sx={{
                                    ...theme.typography.mediumAvatar,
                                    // margin: '8px 0 8px 8px !important',
                                    cursor: 'pointer',
                                    width: 200,
                                    height: 200
                                }}
                                aria-haspopup="true"
                                color="inherit"
                            >
                                <IconUser />
                            </Avatar>
                        ) : (
                            // solo los alumnos tienen opcion de editar su foto de perfil
                            <CompEditarFoto urlActual={context?.login?.usuario?.foto} />
                        )}
                    </Grid>
                    <Grid item xs={12} md={9} container spacing={2}>
                        {permisos.cambiarContrasena && (
                            <Grid item xs={3}>
                                <TextField
                                    label="Usuario"
                                    value={usuarioDetalle.usuario}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        )}
                        {permisos.cambiarContrasena && (
                            <Grid item xs={3}>
                                <TextField
                                    label="Rol"
                                    value={usuarioDetalle.nrol}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <TextField
                                label="Nombre"
                                value={usuarioDetalle.nombreUsuario}
                                InputProps={{ readOnly: true }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Correo"
                                value={usuarioDetalle.correo}
                                InputProps={{ readOnly: true }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Teléfono"
                                value={usuarioDetalle.telefono}
                                InputProps={{ readOnly: true }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Activo desde"
                                value={usuarioDetalle.fcrea || '---'}
                                InputProps={{ readOnly: true }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Primer acceso"
                                value={usuarioDetalle.primerConeccion}
                                InputProps={{ readOnly: true }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Último acceso"
                                value={usuarioDetalle.ultimaConeccion}
                                InputProps={{ readOnly: true }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
            <br />
            {permisos.cambiarContrasena && (
                <SubCardCollapase title="Cambio de contraseña">
                    <form onSubmit={handleSubmit(cambiarContrasena)}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    label="Contraseña actual"
                                    size="small"
                                    fullWidth
                                    type="password"
                                    {...register('contrasenaActual', { required: { value: true, message: 'Campo requerido' } })}
                                    error={Boolean(errors.contrasenaActual)}
                                    helperText={errors.contrasenaActual?.message}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Contraseña"
                                    size="small"
                                    fullWidth
                                    type="password"
                                    {...register('contrasena1', { required: { value: true, message: 'Campo requerido' } })}
                                    error={Boolean(errors.contrasena1)}
                                    helperText={errors.contrasena1?.message}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Confirmar Contraseña"
                                    size="small"
                                    type="password"
                                    fullWidth
                                    {...register('contrasena2', {
                                        required: {
                                            value: true,
                                            message: 'Campo requerido'
                                        },
                                        validate: (value) => value == getValues('contrasena1') || 'Las contraseñas no coinciden'
                                    })}
                                    error={Boolean(errors.contrasena2)}
                                    helperText={errors.contrasena2?.message}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" type="submit">
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </SubCardCollapase>
            )}
            <AvisoConfirmacion
                open={confirmacion.open}
                title="Advertencia"
                text="¿Estás seguro de cambiar tu contraseña? Asegúrese de recordar la nueva contraseña antes de aceptar."
                accept={aceptarConfirmacion}
                cancel={cerrarConfimacion}
            />
        </>
    );
};
export default PerfilUsuario;
