import { AddBox, Reorder } from '@mui/icons-material';
import LayautContext from 'Context/ContextLayaut';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { ModalGeneral, Tabla } from 'ui-component';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import ServiciosCurso from './ServiciosCurso';
import FormPreguntasActividad from './FormPreguntasActividad';
import { Grid, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import FormOrdenar from './FormOrdenar';

const Item = styled(Typography)(({ theme }) => ({
    ...theme.typography.body1,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));
const useStyles = makeStyles((theme) => ({
    respuestaCorrecta: {
        background: theme.palette.success.main,
        border: 0,
        borderRadius: 3,
        color: 'white'
    }
}));
const columns = [
    { label: 'Pregunta', name: 'npregunta', filtrar: true },
    {
        label: 'Tipo',
        name: 'ntipoPregunta',
        filtrar: {
            id: 'idTipoPregunta',
            value: 'ntipoPregunta',
            ctl: [
                { idTipoPregunta: '0', ntipoPregunta: 'Abierta' },
                { idTipoPregunta: '1', ntipoPregunta: 'Cerrada' }
            ]
        }
    }
];

const ModalPreguntasActividad = ({ detalleActividad, permisos, onCambio }) => {
    const classes = useStyles();
    const context = useContext(LayautContext);
    const servicios = ServiciosCurso();
    const [pregunta, setPregunta] = useState([]);
    const [modalEliminarPregunta, setModalEliminarPregunta] = useState({ open: false, text: '' });

    const actualizarData = (nuevaPregunta = [], accion) => {
        if (accion == -1) {
            const idData = pregunta.map((oc) => oc.idPregunta);
            nuevaPregunta.forEach((preguntaI) => {
                const indice = idData.indexOf(preguntaI.idPregunta);
                if (indice >= 0) pregunta.splice(indice, 1);
            });
            setPregunta([...pregunta]);
        } else {
            const idData = pregunta.map((oc) => oc.idPregunta);
            nuevaPregunta.forEach((preguntaI) => {
                const indice = idData.indexOf(preguntaI.idPregunta);
                if (indice >= 0) pregunta.splice(indice, 1, preguntaI);
                else pregunta.splice(0, 0, preguntaI);
            });
            setPregunta([...pregunta]);
        }
    };
    const abrirNuevoPregunta = () => () =>
        context.formulario.abrir({
            open: true,
            titulo: 'Nueva pregunta',
            formulario: FormPreguntasActividad,
            aceptar: (data) => {
                if (pregunta.find((prg) => prg.npregunta.trim() == data.npregunta.trim()))
                    return context.mensaje({ open: true, status: 0, value: 'La pregunta ya ha sido registrada previamente' });

                if (Number(detalleActividad.idTipoActividad) == 3) {
                    const respuestaCorrecta = data.respuestas?.find((res) => res.isCorrecta);
                    if (data.respuestas?.length > 0 && !respuestaCorrecta)
                        // puedo quitar la validacion para que no haya pregunta correcta
                        return context.mensaje({ open: true, status: 0, value: 'Seleccione una respuesta correcta.' });
                }

                servicios.insertPreguntaActividad(
                    (res) => {
                        if (onCambio) onCambio(res);
                        setPregunta(res.pregunta.filter((x) => x.estatus != -1));
                        context.formulario.cerrar();
                    },
                    { ...data, idDetalleActividad: detalleActividad.idDetalleActividad, idTipoPregunta: data.idTipoPregunta == '1' }
                );
                return true;
            }
        });
    const abrirEditarPregunta = (preguntaUpdate) => {
        context.formulario.abrir({
            open: true,
            titulo: `Editar la pregunta ${preguntaUpdate.npregunta}`,
            formulario: FormPreguntasActividad,
            propsForm: { data: { ...preguntaUpdate, idTipoPregunta: preguntaUpdate.idTipoPregunta == '1' } },
            aceptar: (data) => {
                if (pregunta.find((prg) => prg.idPregunta != preguntaUpdate.idPregunta && prg.npregunta.trim() == data.npregunta.trim()))
                    return context.mensaje({ open: true, status: 0, value: 'La pregunta ya ha sido registrada previamente' });

                if (Number(detalleActividad.idTipoActividad) == 3) {
                    const respuestaCorrecta = data.respuestas?.find((res) => res.isCorrecta);
                    if (data.respuestas?.length > 0 && !respuestaCorrecta)
                        // puedo quitar la validacion para que no haya pregunta correcta
                        return context.mensaje({ open: true, status: 0, value: 'Seleccione una respuesta correcta.' });
                }
                servicios.updatePreguntaActividad(
                    (res) => {
                        if (onCambio) onCambio(res);
                        actualizarData(res.pregunta, 1);
                        context.formulario.cerrar();
                    },
                    {
                        ...data,
                        idPregunta: preguntaUpdate.idPregunta,
                        idDetalleActividad: detalleActividad.idDetalleActividad,
                        idTipoPregunta: data.idTipoPregunta == '1'
                    }
                );
                return true;
            }
        });
    };
    const abrirEliminarPregunta = (accion) => (pregunta) => {
        let texto = '';

        switch (accion) {
            case 0:
                texto = `¿Estás seguro de desactivar la pregunta "${pregunta.npregunta}"?`;
                break;
            case 1:
                texto = `¿Estás seguro de  volver a activar la pregunta "${pregunta.npregunta}"?`;
                break;
            case -1:
                texto = `La pregunta "${pregunta.npregunta}" ya no estará disponible en este formulario. ¿Desea continuar?`;
                break;

            default:
                break;
        }
        setModalEliminarPregunta({
            open: true,
            text: texto,
            aceptar: () =>
                servicios.cambiarEstatusPregunta(
                    (res) => {
                        if (onCambio) onCambio(res);
                        actualizarData(res.pregunta, accion);
                        setModalEliminarPregunta({ ...modalEliminarPregunta, open: false });
                    },
                    { idPregunta: pregunta.idPregunta, estatus: accion }
                )
        });
    };

    const abrirOrdenar = () => () => {
        context.formulario.abrir({
            open: true,
            width: 'sm',
            titulo: 'Ordenar Materiales',
            propsForm: { data: { dataOrden: pregunta }, ctl: {} },
            formulario: FormOrdenar,
            aceptar: (dataForm) => {
                if (dataForm.dataOrdenada == undefined) return context.mensaje({ open: true, value: 'No se ha hacho ningun orden' });
                servicios.updateOrdenDetalleActividadExamen(
                    (res) => {
                        setPregunta(res.pregunta.filter((x) => x.estatus != -1));
                        context.formulario.cerrar();
                    },
                    { ...dataForm, idDetalleActividad: detalleActividad.idDetalleActividad }
                );
                return true;
            }
        });
    };
    const accionesTabla = [
        {
            lable: 'Información',
            component: Typography,
            props: { children: '(No se puede asignar y/o editar preguntas de un examen inactivo)', variant: 'subtitle1', color: 'error' },
            disabled: detalleActividad?.estatus == 1
        },
        {
            label: 'Nueva pregunta',
            icon: <AddBox />,
            click: abrirNuevoPregunta,
            disabled: detalleActividad.estatus != '1' || !permisos.crear
        },
        { label: 'Ordenar material', icon: <Reorder />, click: abrirOrdenar, disabled: !permisos.editar }
    ];
    const accionesFila = [
        {
            label: 'Editar',
            onClick: abrirEditarPregunta,
            enabled: (pregunta) => permisos.editarPregunta && detalleActividad.estatus == '1' && pregunta.estatus == '1'
        },
        {
            label: 'Desactivar Pregunta',
            onClick: abrirEliminarPregunta(0),
            enabled: (pregunta) => permisos.editarPregunta && detalleActividad.estatus == '1' && pregunta.estatus == '1'
        },
        {
            label: 'Volver activar Pregunta',
            onClick: abrirEliminarPregunta(1),
            enabled: (pregunta) => permisos.editarPregunta && detalleActividad.estatus == '1' && pregunta.estatus == '0'
        },
        {
            label: 'Eliminar pregunta',
            onClick: abrirEliminarPregunta(-1),
            enabled: true
        }
    ];
    useEffect(() => {
        setPregunta(detalleActividad?.preguntas?.filter((x) => x.estatus != -1) || []);
    }, [detalleActividad]);
    return (
        <>
            <Tabla
                rows={pregunta}
                columns={columns}
                onError={(cst) => cst.estatus != 1}
                accionesTabla={accionesTabla}
                accionesFila={accionesFila}
                columnKey="idPregunta"
                DetailByrow={(row) => (
                    <Grid container spacing={2}>
                        {row?.respuestas?.map((rsp) => (
                            <Grid item xs={3} key={rsp.nrespuesta}>
                                <Item
                                    className={rsp.isCorrecta == '1' ? classes.respuestaCorrecta : null}
                                    color="purple"
                                >{`R- ${rsp.nrespuesta}`}</Item>
                            </Grid>
                        ))}
                    </Grid>
                )}
            />
            <AvisoConfirmacion
                open={modalEliminarPregunta.open}
                title="Advertencia"
                text={modalEliminarPregunta.text}
                accept={modalEliminarPregunta.aceptar}
                cancel={() => setModalEliminarPregunta({ ...modalEliminarPregunta, open: false })}
            />
        </>
    );
};

export default ModalPreguntasActividad;
