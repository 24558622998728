import React, { useState, useEffect, useContext } from 'react';
import { Button, Grid, Tab, Tabs, Typography, Box, FormControlLabel, Checkbox } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ModalGeneral, Tabla, AutocompleteForm } from 'ui-component';
import Services from './Components/services';
import LayautContext from 'Context/ContextLayaut';

const ReporteGeneral = ({ permisos }) => {
    const { control, getValues, setValue } = useForm();
    const services = Services();
    const context = useContext(LayautContext);
    const [tiposCurso, setTiposCurso] = useState([]);
    const [checked, setChecked] = useState(false);
    const [listaCursos, setListaCursos] = useState([]);
    const [listaActividades, setListaActividades] = useState([]);
    const [datosReporteCurso, setDatosReporte] = useState([]);
    const [muestraCurso, setMuestraCurso] = useState(false);
    const [sesiones, setSesiones] = useState([]);
    const [arrSesion, setArrSesion] = useState([]);
    const [columnasMostrar, setColumnasMostrar] = useState([]);

    const columnsReporte = [
        { label: 'número de empleado', name: 'num_empleado', filtrar: true },
        { label: 'nombre', name: 'nombre_persona', filtrar: true },
        { label: 'tipo', name: 'tipo_puesto', filtrar: true },
        { label: 'departamento', name: 'nom_area', filtrar: true },
        { label: 'área', name: 'nom_subarea', filtrar: true },
        { label: 'puesto', name: 'nom_puesto', filtrar: true },
        { label: 'promedio', name: 'promedio', filtrar: true },
        { label: 'porcentaje de avance', name: 'avance', filtrar: true }
    ];

    const columnsReporteAct = [
        { label: 'número de empleado', name: 'num_empleado', filtrar: true },
        { label: 'nombre', name: 'nombre_persona', filtrar: true },
        { label: 'tipo', name: 'tipo_puesto', filtrar: true },
        { label: 'departamento', name: 'nom_area', filtrar: true },
        { label: 'área', name: 'nom_subarea', filtrar: true },
        { label: 'puesto', name: 'nom_puesto', filtrar: true },
        { label: 'Actividad', name: 'titulo', filtrar: true },
        { label: 'intentos por evaluación', name: 'numRespondidos', filtrar: true },
        { label: 'Promedio Actividad', name: 'calificacion', filtrar: true },
        { label: 'Progreso actividad', name: 'avance', filtrar: true }
    ];

    const apruebaCurso = (row) => {
        const curso = getValues('itemCurso')?.idCurso;
        context.modal.confirmar({
            open: true,
            title: 'Validar aprobación del curso',
            text: 'Si el colaborador ha completado todas sus evaluaciones puede aprobar el curso',
            aceptar: () => {
                context.modal.cerrar();
                services.validarAprobacion(
                    (data) => {
                        console.log('aprobado', data);
                    },
                    {
                        idCurso: curso,
                        idColaborador: row.idColaborador
                    }
                );
            }
        });
    };

    const acciones = [
        { label: 'Verificar aprobación', onClick: apruebaCurso, enabled: (row) => permisos.aprCur && Number(row.numAvance) >= 100 }
    ];

    const handleChangeCheck = (event) => {
        setChecked(event.target.checked);
    };

    const handleChangeTipo = (obj) => {
        if (obj) {
            services.buscaCursoByTipo(
                (ct) => {
                    setListaCursos(ct.cursos || []);
                    setMuestraCurso(true);
                },
                {
                    tipoBuscar: obj.idClasificacionCurso
                }
            );
        } else {
            setMuestraCurso(false);
        }
    };

    const buscaActividades = (obj) => {
        if (obj) {
            services.buscaActividades(
                (ct) => {
                    setListaActividades(ct.actividades || []);
                },
                {
                    idSesion: obj.idSesion
                }
            );
        } else {
            setMuestraCurso(false);
        }
    };

    const filtraSesionesCurso = (obj) => {
        if (obj) {
            let arr = sesiones.filter((x) => x.idCurso == obj.idCurso);
            setArrSesion([...arr]);
        }
    };

    const buscaReporte = () => {
        if (checked == true) {
            setColumnasMostrar([...columnsReporteAct]);

            const act = getValues('itemActividad')?.idActividad;
            if (act) {
                services.getReporteCursoAct(
                    (ct) => {
                        setDatosReporte(ct.registros);
                    },
                    {
                        idActividad: act
                    }
                );
            }
        } else {
            setColumnasMostrar([...columnsReporte]);

            const tipo = getValues('tipoCurso')?.idClasificacionCurso;
            const curso = getValues('itemCurso')?.idCurso;

            if (tipo && curso) {
                services.getReporteCurso(
                    (ct) => {
                        setDatosReporte(ct.registros);
                    },
                    {
                        idCurso: curso
                    }
                );
            }
        }
    };

    useEffect(() => {
        setColumnasMostrar([...columnsReporte]);
        services.grupoInt((ct) => {
            setTiposCurso(ct.tiposCursos || []);
            setSesiones(ct.sesiones || []);
        });
    }, []);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12} container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <AutocompleteForm
                            label="Clasificación de curso"
                            control={control}
                            name="tipoCurso"
                            rules={{ required: { value: true, message: 'Campo requerido' } }}
                            options={tiposCurso}
                            optionSelected={{ label: 'nClasificacioncurso', id: 'idClasificacionCurso' }}
                            onChange={handleChangeTipo}
                        />
                    </Grid>
                    {muestraCurso == true && (
                        <Grid item xs={3}>
                            <AutocompleteForm
                                id="listaCursos"
                                label="Curso"
                                control={control}
                                name="itemCurso"
                                rules={{ required: { value: true, message: 'Campo requerido' } }}
                                options={listaCursos}
                                optionSelected={{ label: 'titulo', id: 'idCurso' }}
                                onChange={filtraSesionesCurso}
                            />
                        </Grid>
                    )}

                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Checkbox checked={checked} onChange={handleChangeCheck} name="checked" color="secondary" />}
                            label="Buscar por actividad"
                        />
                    </Grid>
                    {checked && (
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={3}>
                                <AutocompleteForm
                                    id="listaSesiones"
                                    label="Sesión"
                                    control={control}
                                    name="itemSesion"
                                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                                    options={arrSesion}
                                    optionSelected={{ label: 'titulo', id: 'idSesion' }}
                                    onChange={buscaActividades}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteForm
                                    id="listaActividades"
                                    label="Actividad"
                                    control={control}
                                    name="itemActividad"
                                    rules={{ required: { value: true, message: 'Campo requerido' } }}
                                    options={listaActividades}
                                    optionSelected={{ label: 'titulo', id: 'idActividad' }}
                                />
                            </Grid>
                        </Grid>
                    )}

                    {muestraCurso == true && (
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary" onClick={buscaReporte}>
                                Buscar
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Tabla rows={datosReporteCurso} columns={columnasMostrar} accionesFila={acciones} columnKey="num_empleado" />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ReporteGeneral;
